import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import FirstPage from '../views/first-page.vue'
import SecondPage from '../views/second-page.vue'
import ThirdPage from '../views/third-page.vue'
import FourthPage from '../views/fourth-page.vue'
import FifthPage from '../views/fifth-page.vue'
import SixthPage from '../views/sixth-page.vue'
import LandingPage from '../views/landing-page.vue'
const routes: Array<RouteRecordRaw> = [
  {
    path:'/',
    name:'landingPage',
    component:LandingPage
  },
 {
  path:'/first-page',
  name:'firstPage',
  component:FirstPage
 },
 {
  path:'/second-page',
  name:'secondPage',
  component:SecondPage
 },
 {
  path:'/third-page',
  name:'thirdPage',
  component:ThirdPage
 },
 {
  path:'/fourth-page',
  name:'fourthPage',
  component:FourthPage
 },
 {
  path:'/fifth-page',
  name:'fifthPage',
  component:FifthPage
 },
 {
  path:'/sixth-page',
  name:'sixthPage',
  component:SixthPage
 },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
