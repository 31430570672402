<template>
    <div id="container">
        <header-comp @go-to="goto"></header-comp>
        <div id="comp-container" v-on:scroll="handleScroll" ref="scroll-container">
        <div ref="first-page">
            <first-page @go-to="goto"></first-page>
        </div>
        <div ref="second-page">
            <second-page></second-page>
        </div>
        <div ref="third-page">
            <third-page></third-page>
        </div>
        <div ref="fourth-page">
            <fourth-page></fourth-page>
        </div>
        <div ref="fifth-page">
            <fifth-page></fifth-page>
        </div>
       <div ref="sixth-page">
        <sixth-page @go-to="goto"></sixth-page>
       </div>
       <btn-comp v-if="Btn" @top-scroll="goTop"></btn-comp>
        </div>
        <footer-comp @go-to="goto"></footer-comp>
    </div>
</template>

<script>
import HeaderComp from '../components/general/header-comp.vue'
import FooterComp from '../components/general/footer-comp.vue'
import FirstPage from '../components/first-page/web-comp.vue'
import SecondPage from '../components/second-page/web-comp.vue'
import ThirdPage from '../components/third-page/web-comp.vue'
import FourthPage from '../components/fourth-page/web-comp.vue'
import FifthPage from '../components/fifth-page/web-comp.vue'
import SixthPage from '../components/sixth-page/web-comp.vue'
import BtnComp from '../components/general/button-comp.vue'
export default {
data(){
    return{
        btn:false
    }
},
computed:{
    Btn(){
        return this.btn;
    },
    
},
    components:{HeaderComp,FooterComp,FirstPage,SecondPage,ThirdPage,FourthPage,FifthPage,SixthPage,BtnComp},
    methods: {
    goto(val) {
        const el = this.$refs[val];

    if (el) {
      // Use el.scrollIntoView() to instantly scroll to the element
      el.scrollIntoView({behavior: 'smooth'});
    }
    },
    handleScroll(event){
        let scrolled = this.$refs['scroll-container'].scrollTop
        let offset1 = this.$refs['first-page'].offsetTop 
        let offset2 = this.$refs['second-page'].offsetTop 
        let offset3 = this.$refs['third-page'].offsetTop 
        let offset4 = this.$refs['fourth-page'].offsetTop 
        let offset5 = this.$refs['fifth-page'].offsetTop 
        let offset6 = this.$refs['sixth-page'].offsetTop 

        if(scrolled<=offset1) {
            this.btn = false;
            this.$store.commit('setPage',{value:0});
        }
        if(scrolled>offset1 && scrolled<=offset2) {
            this.btn=true;
            this.$store.commit('setPage',{value:1})
        }
        if(scrolled>offset2 && scrolled<=offset3){
            this.btn=true;
            this.$store.commit('setPage',{value:2})
        }
        if(scrolled>offset3 && scrolled<=offset4){
            this.btn = true;
            this.$store.commit('setPage',{value:3})
        }
        if(scrolled>offset4 && scrolled<=offset5){
            this.btn = true;
            this.$store.commit('setPage',{value:4})
        }
        if(scrolled>offset5 ){
            this.btn = true;
            this.$store.commit('setPage',{value:5})
        }
        if(scrolled>offset6){
            this.btn = false;
        }
    },
    goTop(){
        const el = this.$refs['first-page']
        el.scrollIntoView({behavior: 'smooth'});
    }
   
  },

}
</script>

<style scoped>
@media only screen and (min-width:961px){
    #container{
        margin:0px;
        padding:0px;
        position:fixed;
        top:0;
        left:0;
        overflow-x:hidden ;
        overflow-y:auto;
    
    }
    #comp-container::-webkit-scrollbar{
        display: none;
    }
    #comp-container{
       overflow-y:auto;
       width:100vw;
       height:100vh;
       overflow-x:hidden
    }
}
@media only screen and (min-width:481px) and (max-width:960px){
    #container{
        margin:0px;
        padding:0px;
        position:fixed;
        top:0;
        left:0;
        overflow-x:hidden ;
        overflow-y:auto;
    
    }
    #comp-container::-webkit-scrollbar{
        display: none;
    }
    #comp-container{
        overflow-y:auto;
        width:100vw;
        height:100vh;
        overflow-x:hidden
     }
}
@media only screen and (max-width:480px){
    #container{
        margin:0px;
        padding:0px;
        position:fixed;
        top:0;
        left:0;
        overflow-x:hidden ;
        overflow-y:auto;
    
    }
    #comp-container::-webkit-scrollbar{
        display: none;
    }
    #comp-container{
        width:100vw;
        height:94vh;
        overflow-x:hidden ;
        overflow-y:auto;
        position:fixed;
        left:0;
    }
}
</style>