<template>
    <div id="fifth-desktop" :style="bg">
        <h2 :class="theme==0?'text-theme-1':theme==1?'text-theme-2':'text-theme-3'">Portfolio</h2>
        <h3 :class="theme==0?'h3-theme-1':theme==1?'h3-theme-2':'h3-theme-3'">My Work</h3>
        <div class="carousel">
<div class="slides">
    <div class="slide" v-for="(item, index) in projects"
    :key="index"
    :style="{ transform: `translateX(${index * -100}%)` }">
    <div v-if="index == Curr" id="carousel-container">
        <div id="carousel-image" >
            <img :src="item['images'][0]" :alt="item['title']" :class="theme==0?'car-img-1':theme==1?'car-img-2':'car-img-3'">
        </div>
        <div id="carousel-text">
            <h2 :class="theme==0?'title-1':theme==1?'title-2':'title-3'">{{ item['title'] }}</h2>
            <h3 :class="theme==0?'description-1':theme==1?'description-2':'description-3'">{{ item['description'] }}</h3>
        </div>
        <div id="carousel-buttons">
            <div :class="theme==0?'car-btn-1':theme==1?'car-btn-2':'car-btn-3'" @click="goto(item['github'])" style="display:flex;align-items:center">
                <h3>Github</h3>
                <svg xmlns="http://www.w3.org/2000/svg" width="44" height="37" viewBox="0 0 44 37" fill="none" v-if="theme==1">
                  <g filter="url(#filter0_d_235_818)">
                    <path d="M4.45542 0.245968C4.64748 0.114699 4.88316 0.0317638 5.13322 0.00745869C5.38327 -0.0168464 5.63668 0.0185496 5.86198 0.109252L39.2904 13.5737C39.5037 13.6599 39.683 13.7921 39.8083 13.9556C39.9336 14.1191 40 14.3075 40 14.4997C40 14.6919 39.9336 14.8803 39.8083 15.0438C39.683 15.2073 39.5037 15.3395 39.2904 15.4256L5.86198 28.8901C5.63684 28.9811 5.38349 29.0167 5.1334 28.9927C4.88331 28.9687 4.64749 28.886 4.45521 28.7549C4.26294 28.6239 4.12269 28.4502 4.05187 28.2555C3.98105 28.0608 3.98278 27.8536 4.05685 27.6597L9.08397 14.4997L4.05685 1.3397C3.98321 1.14597 3.98172 0.939097 4.05256 0.744691C4.1234 0.550286 4.26347 0.376888 4.45542 0.245968ZM11.3777 15.5354L7.39969 25.9549L35.8395 14.4997L7.39969 3.04451L11.3777 13.464H25.8573C26.1983 13.464 26.5253 13.5731 26.7664 13.7673C27.0076 13.9616 27.143 14.225 27.143 14.4997C27.143 14.7744 27.0076 15.0378 26.7664 15.2321C26.5253 15.4263 26.1983 15.5354 25.8573 15.5354H11.3777Z" fill="#344E41"/>
                  </g>
                  <defs>
                    <filter id="filter0_d_235_818" x="0" y="0" width="44" height="37" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                      <feFlood flood-opacity="0" result="BackgroundImageFix"/>
                      <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                      <feOffset dy="4"/>
                      <feGaussianBlur stdDeviation="2"/>
                      <feComposite in2="hardAlpha" operator="out"/>
                      <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"/>
                      <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_235_818"/>
                      <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_235_818" result="shape"/>
                    </filter>
                  </defs>
                </svg>
                <svg xmlns="http://www.w3.org/2000/svg" width="44" height="37" viewBox="0 0 44 37" fill="none" v-if="theme==0">
                  <g filter="url(#filter0_d_235_819)">
                    <path d="M4.45542 0.245968C4.64748 0.114699 4.88316 0.0317638 5.13322 0.00745869C5.38327 -0.0168464 5.63668 0.0185496 5.86198 0.109252L39.2904 13.5737C39.5037 13.6599 39.683 13.7921 39.8083 13.9556C39.9336 14.1191 40 14.3075 40 14.4997C40 14.6919 39.9336 14.8803 39.8083 15.0438C39.683 15.2073 39.5037 15.3395 39.2904 15.4256L5.86198 28.8901C5.63684 28.9811 5.38349 29.0167 5.1334 28.9927C4.88331 28.9687 4.64749 28.886 4.45521 28.7549C4.26294 28.6239 4.12269 28.4502 4.05187 28.2555C3.98105 28.0608 3.98278 27.8536 4.05685 27.6597L9.08397 14.4997L4.05685 1.3397C3.98321 1.14597 3.98172 0.939097 4.05256 0.744691C4.1234 0.550286 4.26347 0.376888 4.45542 0.245968ZM11.3777 15.5354L7.39969 25.9549L35.8395 14.4997L7.39969 3.04451L11.3777 13.464H25.8573C26.1983 13.464 26.5253 13.5731 26.7664 13.7673C27.0076 13.9616 27.143 14.225 27.143 14.4997C27.143 14.7744 27.0076 15.0378 26.7664 15.2321C26.5253 15.4263 26.1983 15.5354 25.8573 15.5354H11.3777Z" fill="#A3B18A"/>
                  </g>
                  <defs>
                    <filter id="filter0_d_235_819" x="0" y="0" width="44" height="37" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                      <feFlood flood-opacity="0" result="BackgroundImageFix"/>
                      <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                      <feOffset dy="4"/>
                      <feGaussianBlur stdDeviation="2"/>
                      <feComposite in2="hardAlpha" operator="out"/>
                      <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"/>
                      <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_235_819"/>
                      <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_235_819" result="shape"/>
                    </filter>
                  </defs>
                </svg>
                <svg xmlns="http://www.w3.org/2000/svg" width="44" height="37" viewBox="0 0 44 37" fill="none" v-if="theme==2 || theme==3">
                  <g filter="url(#filter0_d_235_820)">
                    <path d="M4.45542 0.245968C4.64748 0.114699 4.88316 0.0317638 5.13322 0.00745869C5.38327 -0.0168464 5.63668 0.0185496 5.86198 0.109252L39.2904 13.5737C39.5037 13.6599 39.683 13.7921 39.8083 13.9556C39.9336 14.1191 40 14.3075 40 14.4997C40 14.6919 39.9336 14.8803 39.8083 15.0438C39.683 15.2073 39.5037 15.3395 39.2904 15.4256L5.86198 28.8901C5.63684 28.9811 5.38349 29.0167 5.1334 28.9927C4.88331 28.9687 4.64749 28.886 4.45521 28.7549C4.26294 28.6239 4.12269 28.4502 4.05187 28.2555C3.98105 28.0608 3.98278 27.8536 4.05685 27.6597L9.08397 14.4997L4.05685 1.3397C3.98321 1.14597 3.98172 0.939097 4.05256 0.744691C4.1234 0.550286 4.26347 0.376888 4.45542 0.245968ZM11.3777 15.5354L7.39969 25.9549L35.8395 14.4997L7.39969 3.04451L11.3777 13.464H25.8573C26.1983 13.464 26.5253 13.5731 26.7664 13.7673C27.0076 13.9616 27.143 14.225 27.143 14.4997C27.143 14.7744 27.0076 15.0378 26.7664 15.2321C26.5253 15.4263 26.1983 15.5354 25.8573 15.5354H11.3777Z" fill="#D4ADFC"/>
                  </g>
                  <defs>
                    <filter id="filter0_d_235_820" x="0" y="0" width="44" height="37" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                      <feFlood flood-opacity="0" result="BackgroundImageFix"/>
                      <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                      <feOffset dy="4"/>
                      <feGaussianBlur stdDeviation="2"/>
                      <feComposite in2="hardAlpha" operator="out"/>
                      <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"/>
                      <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_235_820"/>
                      <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_235_820" result="shape"/>
                    </filter>
                  </defs>
                </svg>
            </div>
            <div :class="theme==0?'car-btn-1':theme==1?'car-btn-2':'car-btn-3'" @click="goto(item['demo'])" style="display:flex;align-items:center">
                <h3>Demo</h3>
                <svg xmlns="http://www.w3.org/2000/svg" width="44" height="37" viewBox="0 0 44 37" fill="none" v-if="theme==1">
                  <g filter="url(#filter0_d_235_818)">
                    <path d="M4.45542 0.245968C4.64748 0.114699 4.88316 0.0317638 5.13322 0.00745869C5.38327 -0.0168464 5.63668 0.0185496 5.86198 0.109252L39.2904 13.5737C39.5037 13.6599 39.683 13.7921 39.8083 13.9556C39.9336 14.1191 40 14.3075 40 14.4997C40 14.6919 39.9336 14.8803 39.8083 15.0438C39.683 15.2073 39.5037 15.3395 39.2904 15.4256L5.86198 28.8901C5.63684 28.9811 5.38349 29.0167 5.1334 28.9927C4.88331 28.9687 4.64749 28.886 4.45521 28.7549C4.26294 28.6239 4.12269 28.4502 4.05187 28.2555C3.98105 28.0608 3.98278 27.8536 4.05685 27.6597L9.08397 14.4997L4.05685 1.3397C3.98321 1.14597 3.98172 0.939097 4.05256 0.744691C4.1234 0.550286 4.26347 0.376888 4.45542 0.245968ZM11.3777 15.5354L7.39969 25.9549L35.8395 14.4997L7.39969 3.04451L11.3777 13.464H25.8573C26.1983 13.464 26.5253 13.5731 26.7664 13.7673C27.0076 13.9616 27.143 14.225 27.143 14.4997C27.143 14.7744 27.0076 15.0378 26.7664 15.2321C26.5253 15.4263 26.1983 15.5354 25.8573 15.5354H11.3777Z" fill="#344E41"/>
                  </g>
                  <defs>
                    <filter id="filter0_d_235_818" x="0" y="0" width="44" height="37" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                      <feFlood flood-opacity="0" result="BackgroundImageFix"/>
                      <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                      <feOffset dy="4"/>
                      <feGaussianBlur stdDeviation="2"/>
                      <feComposite in2="hardAlpha" operator="out"/>
                      <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"/>
                      <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_235_818"/>
                      <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_235_818" result="shape"/>
                    </filter>
                  </defs>
                </svg>
                <svg xmlns="http://www.w3.org/2000/svg" width="44" height="37" viewBox="0 0 44 37" fill="none" v-if="theme==0">
                  <g filter="url(#filter0_d_235_819)">
                    <path d="M4.45542 0.245968C4.64748 0.114699 4.88316 0.0317638 5.13322 0.00745869C5.38327 -0.0168464 5.63668 0.0185496 5.86198 0.109252L39.2904 13.5737C39.5037 13.6599 39.683 13.7921 39.8083 13.9556C39.9336 14.1191 40 14.3075 40 14.4997C40 14.6919 39.9336 14.8803 39.8083 15.0438C39.683 15.2073 39.5037 15.3395 39.2904 15.4256L5.86198 28.8901C5.63684 28.9811 5.38349 29.0167 5.1334 28.9927C4.88331 28.9687 4.64749 28.886 4.45521 28.7549C4.26294 28.6239 4.12269 28.4502 4.05187 28.2555C3.98105 28.0608 3.98278 27.8536 4.05685 27.6597L9.08397 14.4997L4.05685 1.3397C3.98321 1.14597 3.98172 0.939097 4.05256 0.744691C4.1234 0.550286 4.26347 0.376888 4.45542 0.245968ZM11.3777 15.5354L7.39969 25.9549L35.8395 14.4997L7.39969 3.04451L11.3777 13.464H25.8573C26.1983 13.464 26.5253 13.5731 26.7664 13.7673C27.0076 13.9616 27.143 14.225 27.143 14.4997C27.143 14.7744 27.0076 15.0378 26.7664 15.2321C26.5253 15.4263 26.1983 15.5354 25.8573 15.5354H11.3777Z" fill="#A3B18A"/>
                  </g>
                  <defs>
                    <filter id="filter0_d_235_819" x="0" y="0" width="44" height="37" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                      <feFlood flood-opacity="0" result="BackgroundImageFix"/>
                      <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                      <feOffset dy="4"/>
                      <feGaussianBlur stdDeviation="2"/>
                      <feComposite in2="hardAlpha" operator="out"/>
                      <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"/>
                      <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_235_819"/>
                      <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_235_819" result="shape"/>
                    </filter>
                  </defs>
                </svg>
                <svg xmlns="http://www.w3.org/2000/svg" width="44" height="37" viewBox="0 0 44 37" fill="none" v-if="theme==2 || theme==3">
                  <g filter="url(#filter0_d_235_820)">
                    <path d="M4.45542 0.245968C4.64748 0.114699 4.88316 0.0317638 5.13322 0.00745869C5.38327 -0.0168464 5.63668 0.0185496 5.86198 0.109252L39.2904 13.5737C39.5037 13.6599 39.683 13.7921 39.8083 13.9556C39.9336 14.1191 40 14.3075 40 14.4997C40 14.6919 39.9336 14.8803 39.8083 15.0438C39.683 15.2073 39.5037 15.3395 39.2904 15.4256L5.86198 28.8901C5.63684 28.9811 5.38349 29.0167 5.1334 28.9927C4.88331 28.9687 4.64749 28.886 4.45521 28.7549C4.26294 28.6239 4.12269 28.4502 4.05187 28.2555C3.98105 28.0608 3.98278 27.8536 4.05685 27.6597L9.08397 14.4997L4.05685 1.3397C3.98321 1.14597 3.98172 0.939097 4.05256 0.744691C4.1234 0.550286 4.26347 0.376888 4.45542 0.245968ZM11.3777 15.5354L7.39969 25.9549L35.8395 14.4997L7.39969 3.04451L11.3777 13.464H25.8573C26.1983 13.464 26.5253 13.5731 26.7664 13.7673C27.0076 13.9616 27.143 14.225 27.143 14.4997C27.143 14.7744 27.0076 15.0378 26.7664 15.2321C26.5253 15.4263 26.1983 15.5354 25.8573 15.5354H11.3777Z" fill="#D4ADFC"/>
                  </g>
                  <defs>
                    <filter id="filter0_d_235_820" x="0" y="0" width="44" height="37" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                      <feFlood flood-opacity="0" result="BackgroundImageFix"/>
                      <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                      <feOffset dy="4"/>
                      <feGaussianBlur stdDeviation="2"/>
                      <feComposite in2="hardAlpha" operator="out"/>
                      <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"/>
                      <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_235_820"/>
                      <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_235_820" result="shape"/>
                    </filter>
                  </defs>
                </svg>
            </div>
        </div>
    </div>
</div>
</div>
        </div>
        <div class="work" style="margin-bottom:10vh;">
            <div class="image" v-for="work,index in projects" :key="index" @click="goToCurr(index)">
                <img :src="work['images'][0]" :alt="work['title']" :class="theme==0?'image-1':theme==1?'image-2':'image-3'">
            </div>
        </div>
        <h2 :class="theme==0?'text-theme-1':theme==1?'text-theme-2':'text-theme-3'">You have a new project</h2>
        <h3 :class="theme==0?'h3-theme-1':theme==1?'h3-theme-2':'h3-theme-3'">Let's talk about it</h3>
    </div>
</template>

<script>
export default {
    data(){
        return{
            curr:0
        }
    },
    methods:{
        goToCurr(val){
            this.curr=val;
        },
        goto(val){
        window.open(val, '_blank');

      },
    },
    computed:{
        Curr(){
            return this.curr;
        },
        bg(){
          let theme = this.$store.getters['getTheme']
            if(theme==0){
              return `background:#A3B18A`
               
            }
            else if(theme==1){
              return `background:#344E41`
            }
            else if(theme==2){
              return 'background:#D4ADFC;'
            }
            else if(theme==3){
              return 'background:#D4ADFC;'
            }
            else{
              return `background:#A3B18A`
            }
        },
        text(){
            let theme = this.$store.getters['getTheme']
            if(theme==0){
                return `color:#344E41`
            }
            else{
                return `color:`
            }
        },
        theme(){
            return this.$store.getters['getTheme']
        },
    },
    props:['projects']   
}
</script>

<style scoped>
@media only screen and (min-width:961px){
#fifth-desktop{
    width:100vw;
    position: relative;
    height:fit-content;
    overflow-x:hidden;
    overflow-y:auto;
    padding-top:0vh;
    height:fit-content;
    padding-bottom:4vh;
}
.text-theme-1{
    color:#344E41;
font-family: Roboto;
font-size: 48px;
font-style: normal;
font-weight: 700;
line-height: normal;
text-align:center;
margin-bottom:0px;
margin-top:0.7vh;
}
.text-theme-2{
    color:  #A3B18A;
    font-family: Roboto;
    font-size: 48px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    text-align: center;
    margin-bottom:0px;
    margin-top:0.7vh;
}
.text-theme-3{
  color:  #0C134F;
  font-family: Roboto;
  font-size: 48px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-align: center;
  margin-bottom:0px;
  margin-top:0.7vh;
}
.h3-theme-1{
    color: #588157;
    font-family: Roboto;
    font-size: 24px;
    font-style: normal;
    font-weight: 350;
    line-height: normal;
    text-align: center;
    margin-top:2px;
}
.h3-theme-2{
    color: #C8CFCC;
font-family: Roboto;
font-size: 24px;
font-style: normal;
font-weight: 400;
line-height: normal;
    margin-top:2px;
    text-align: center;
}
.h3-theme-3{
  color: #5C469C;;
font-family: Roboto;
font-size: 24px;
font-style: normal;
font-weight: 400;
line-height: normal;
  margin-top:2px;
  text-align: center;
}

.work{
    width:70vw;
    margin-left:15vw;
    overflow-x:auto;
    overflow-y:hidden ;
    display: flex;
    flex-direction: row;
}

.carousel{
    position: relative;
    overflow: hidden;
    width: 100vw;
    height: 50vh;
    margin-top:-4vh; /* Set the desired height of your carousel */
}
.slides {
    display: flex;
    transition: transform 0.3s ease-in-out;
  }
  .slide {
    flex: 0 0 100%;
    width: 100%;
    height: 100%;
  }
  #carousel-container{
      width:100vw;
      display: grid;
    grid-template-columns: 30vw 50vw 20vw;
    animation: slideInFromLeft 1s ease-in-out forwards;
  }
  #carousel-image{
    width:30vw;
    height:fit-content;
    padding-top:1vh;
  }
  #carousel-image img{
    width:25vw;
    height:35vh;
    margin-left:3vw;
    border-radius: 10px;
    margin-top:4.5vh;
  }
  .car-img-1{
border:2px solid #344E41
  }
  .car-img-2{
  border:2px solid #A3B18A;
  }
  .car-img-3{
    border:2px solid #0C134F;
    }
  #carousel-text{
    margin-top:3vh;
  }
  .title-1{
    color: #3A5A40;
font-family: Roboto;
font-size: 42px;
font-style: normal;
font-weight: 600;
line-height: normal;
  }
  .description-1{
    color: #588157;
    font-family: Roboto;
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
  .title-2{
    color: #A3B18A;;
font-family: Roboto;
font-size: 42px;
font-style: normal;
font-weight: 600;
line-height: normal;
  }
  .description-2{
    color: #DAD7CD;
    font-family: Roboto;
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
  .title-3{
    color:#0C134F;;;
font-family: Roboto;
font-size: 42px;
font-style: normal;
font-weight: 600;
line-height: normal;
  }
  .description-3{
    color:#0c134fd5;;
    font-family: Roboto;
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
  #carousel-buttons{
    padding-left:3vw;
    padding-top:8vh;
  }
 
  .car-btn-1{
    width:12vw;
    height:9vh;
    border-radius: 5px;
background: #3A5A40;
box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
margin-top:5vh;
margin-bottom:5vh;
display: grid;
grid-template-columns: 8vw 4vw;
cursor: pointer;
  }
  .car-btn-2{
    width:12vw;
    height:9vh;
    border-radius: 5px;
    background: #A3B18A;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    margin-top:5vh;
margin-bottom:5vh;
display: grid;
grid-template-columns: 8vw 4vw;
cursor: pointer;
  }
  .car-btn-3{
    width:12vw;
    height:9vh;
    border-radius: 5px;
    background:  #1D267D;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    margin-top:5vh;
margin-bottom:5vh;
display: grid;
grid-template-columns: 8vw 4vw;
cursor: pointer;
  }
 
  .car-btn-1 h3{
    color: #A3B18A;
text-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
font-family: Roboto;
font-size: 28px;
font-style: normal;
font-weight: 400;
margin-left:1vw;
line-height: 2vh;
  }
  .car-btn-2 h3{
    color: #344E41;;
text-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
font-family: Roboto;
font-size: 28px;
font-style: normal;
font-weight: 400;
margin-left:1vw;
line-height: 2vh;
  }
  .car-btn-3 h3{
    color: #D4ADFC;
text-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
font-family: Roboto;
font-size: 28px;
font-style: normal;
font-weight: 400;
margin-left:1vw;
line-height: 2vh;
  }
  .car-btn-1 svg,
  .car-btn-2 svg,
  .car-btn-3 svg{
    margin-top:0.5vh;
    margin-left:1vw;
  }
.image{
    margin-left:1vw;
    margin-right:1vw;
    cursor: pointer;
}
.image-1{
    width:10vw;
    height:12vh;
    border-radius: 5px;
    border:2px solid #344E41
}
.image-2{
    width:10vw;
    height:12vh;
    border-radius: 5px;
    border:2px solid #A3B18A
}
.image-3{
  width:10vw;
  height:12vh;
  border-radius: 5px;
  border:2px solid #0C134F;
}
.work::-webkit-scrollbar{
    display: none;
}
@keyframes slideInFromLeft {
    0% {
      transform: translateX(-100%);
      opacity: 0;
    }
    100% {
      transform: translateX(0);
      opacity: 1;
    }
  }
}
@media only screen and (min-width:481px) and (max-width:960px){
  #fifth-desktop{
      display: none;
  }
}
@media only screen and (max-width:480px){
    #fifth-desktop{
        display: none;
    }
}
</style>