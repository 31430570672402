<template>
    <div id="fifth-page">
        <desktop-view :projects="projects"></desktop-view>
        <mobile-view :projects="projects"></mobile-view>
    </div>
</template>

<script>
import DesktopView from './desktop-view.vue'
import MobileView from './mobile-view.vue'
export default {
    components:{DesktopView,MobileView},
    data(){
        return{
            projects:[
                // yelli shoes
                {
                    title:'Yelli Shoes',
                    description:`THis is an online shoe store built using Vuejs and a material design plugin vuetify,this is an exact replica of 
                    NIKE shoe store and has a clean and responsive design.Backend is built upon firebase with authentication and other features
                    added.Shoes data is collected using python scripts/web scrapping using beautiful soup`,
                    images:['https://firebasestorage.googleapis.com/v0/b/portfolio-b3f3a.appspot.com/o/images%2Fyelli-1.png?alt=media&token=2dd04acf-ac81-4628-84c6-4f2b8b2424a8',
                    'https://firebasestorage.googleapis.com/v0/b/portfolio-b3f3a.appspot.com/o/images%2Fyelli-2.png?alt=media&token=3941a382-537c-445c-b0ae-72d7aef4ded6'],
                    github:'https://github.com/abhipray-cpu/Yeli-shoes',
                    demo:'https://yeli-shoes.pages.dev/',
                    type:0
                },
                //facility finder
                {
                    title:'Naksha',
                    description:`This is a facility finder currently servicable at 3 locations,This is a server rendered app that takes your 
                    coordinates and serves you facilities/locations near you,feature of this app is authentication,scalable,responsive design
                    and this is a server rendered app built upon node and mongodb`,
                    images:['https://firebasestorage.googleapis.com/v0/b/portfolio-b3f3a.appspot.com/o/images%2Ffacility%20desktop.png?alt=media&token=1256e593-3164-4762-a71b-96d2e7cf32c0',
                'https://firebasestorage.googleapis.com/v0/b/portfolio-b3f3a.appspot.com/o/images%2Ffacility%20mobile.png?alt=media&token=036f01f8-6d4f-4517-9bc3-125e905b1b5a'],
                    github:'https://github.com/abhipray-cpu/naksha',
                    demo:'http://3.107.20.114/',
                    type:0
                },
                //sasta imdb
                {
                    title:'Sasta IMDB',
                    description:`THis is a cheap replica of IMDB with all the basic featurs implemented like creatinf playlist,ratin and movie
                    and movie suggestions based on movies in your playlist.A server rendered app built usinf Nodejs,expressJS and mongoose,with
                    all the features like authorization,session management added`,
                    images:['https://firebasestorage.googleapis.com/v0/b/portfolio-b3f3a.appspot.com/o/images%2Fsasta-IMDB.png?alt=media&token=415cae08-d643-442e-86da-49ca646473ce','https://firebasestorage.googleapis.com/v0/b/portfolio-b3f3a.appspot.com/o/images%2Fsasta-IMDB-desktop.png?alt=media&token=8666f7b7-5f7b-4edd-95ec-1a2307690394'
                ],
                    github:'https://github.com/abhipray-cpu/sastaImdb',
                    demo:'http://54.253.222.103/',
                    type:0
                },
                //cappy notes
                {
                    title:'Cappy Notes',
                    description:`This is a PWA app build using vue3 and typescript,this is a notes taking app with a classic design using green
                    color pallete,Features of this app is all user data will be stored in cache you can plan your daily tasks and can also track your progress with daily notifications,`,
                    images:['https://firebasestorage.googleapis.com/v0/b/portfolio-b3f3a.appspot.com/o/images%2Fcapp-1.jpg?alt=media&token=3bc8989b-6521-4592-9fd4-4d54d5fabf4d',
                'https://firebasestorage.googleapis.com/v0/b/portfolio-b3f3a.appspot.com/o/images%2Fcapp-2.jpg?alt=media&token=bf80baa5-fb7e-4c18-b7a1-cf5a5f1c11f4'],
                    github:'https://github.com/abhipray-cpu/task-tracker',
                    demo:'https://capy-notes.pages.dev/aaj',
                    type:0
                },
                //chat app
                {
                    title:'Sandesha',
                    description:`This is my dream project an all in one starter pack that will help you in building better connections
                    and achieving  a healthy mental state, a human behavior and pschology app that will help you in identifying you and help
                    you become a better person at the samt time helping you make better connections and enhancing your well being`,
                    images:['https://firebasestorage.googleapis.com/v0/b/portfolio-b3f3a.appspot.com/o/images%2Fchat-1.jpg?alt=media&token=e162052a-62f8-43a4-9569-49844986c185'
                    ,'https://firebasestorage.googleapis.com/v0/b/portfolio-b3f3a.appspot.com/o/images%2Fchat-2.jpg?alt=media&token=0bd66cca-649d-4303-b1d4-df982eb47ffa'],
                    github:'https://github.com/abhipray-cpu/sandesha2.0',
                    demo:'https://sandesha.pages.dev/',
                    type:0
                },
                // encoder-decoder
                {
                    title:'Encoder-Decoder',
                    description:`This is a GUI application build usnig PYQT5 a pythong framework for  building GUI,feature fo this app is that there
                    are multiple hashing algorithms and also key based encryption and decryption.Check this in demo`,
                    images:['https://firebasestorage.googleapis.com/v0/b/portfolio-b3f3a.appspot.com/o/images%2Fencrypt-1.png?alt=media&token=5b097858-2023-44ea-88c4-8627a0da4ef4',
                    'https://firebasestorage.googleapis.com/v0/b/portfolio-b3f3a.appspot.com/o/images%2Fencrypt-2.png?alt=media&token=d1a7be25-3e32-4d98-ab51-62714d07093e'],
                    github:'https://github.com/abhipray-cpu/Encoder-and-decoder',
                    demo:'https://firebasestorage.googleapis.com/v0/b/portfolio-b3f3a.appspot.com/o/main.zip?alt=media&token=9b51c08c-d20b-4fa2-8327-7f998bfa1d1e',
                    type:1
                },
                // text editor
                {
                    title:'Text editor',
                    description:`This is an exact replica of Notepad with additional features of theme configurations,build using Python,You can save
                    your work in files try out different fonts and thems.Check this out`,
                    images:['https://firebasestorage.googleapis.com/v0/b/portfolio-b3f3a.appspot.com/o/images%2Ftext-editor.png?alt=media&token=67d962c7-d7fa-4d23-ba44-c13042f0c9ee',
                'https://firebasestorage.googleapis.com/v0/b/portfolio-b3f3a.appspot.com/o/images%2Ftext-editor1.png?alt=media&token=9958a023-16b7-4fe6-bb44-e5d06030a833'],
                    github:'https://github.com/abhipray-cpu/Notepad',
                    demo:'https://firebasestorage.googleapis.com/v0/b/portfolio-b3f3a.appspot.com/o/text-editor.exe?alt=media&token=26862a13-8532-4e27-9145-8a7834de0280',
                    type:1
                },
                //home automation
                {
                    title:'Home Automation',
                    description:`This is an automation project,using python for gui controller,firebase real time database and 16 channel relay module with node mcu`,
                    images:['https://firebasestorage.googleapis.com/v0/b/portfolio-b3f3a.appspot.com/o/images%2Fhome-automation.png?alt=media&token=b8f64197-2bac-4443-8d52-a89d7f4d521a',
                    'https://firebasestorage.googleapis.com/v0/b/portfolio-b3f3a.appspot.com/o/images%2Fhome-automation-1.png.jpg?alt=media&token=b7546565-738b-4b3b-8631-4999c6b6137b'],
                    github:'https://github.com/abhipray-cpu/home-automatation',
                    demo:'https://firebasestorage.googleapis.com/v0/b/portfolio-b3f3a.appspot.com/o/home-automation.exe?alt=media&token=701f18ed-b623-4b87-ac98-ca64d2ac33c4',
                    type:1
                },
                //gesture control
                {
                    title:'Hand Gesture Control Bot',
                    description:`THis is an arduino project where a 4x4 whell bot is controller by hand gestures,communication is done using RF
                    and gestures are tracked using accelerometer fit in glove`,
                    images:['https://firebasestorage.googleapis.com/v0/b/portfolio-b3f3a.appspot.com/o/images%2Fgesture-1.png?alt=media&token=4775693f-0e80-4c61-b713-a1b7dfc30406',
                    'https://firebasestorage.googleapis.com/v0/b/portfolio-b3f3a.appspot.com/o/images%2Fgesture-2.png?alt=media&token=603a4888-4037-4447-b2de-49995f615e04'],
                    github:'https://github.com/abhipray-cpu/Arduino-Projects',
                    demo:'',
                    type:0
                },
                //robot arm
                {
                    title:'Robot Arm',
                    description:'This is a 6 axis robot arm controlled using a mobile app the communication is done via firebase real time database',
                    images:['https://firebasestorage.googleapis.com/v0/b/portfolio-b3f3a.appspot.com/o/images%2Frobot.png?alt=media&token=aa5557f5-74ae-48eb-be66-d7b88f45cb28','https://firebasestorage.googleapis.com/v0/b/portfolio-b3f3a.appspot.com/o/images%2Frobot.png?alt=media&token=aa5557f5-74ae-48eb-be66-d7b88f45cb28'],
                    github:'https://github.com/abhipray-cpu/Arduino-Projects',
                    demo:'https://robo-arm.pages.dev/',
                    type:0
                },
                // regression
                {
                    title:'Corona Cases Prediction',
                    description:`This is a regression model notebook with end to end implementation,cleaning data,data visualization,
                    initializing multiple models,comparing them on different metics and then hypertuning the best model to achieve best
                    results and accuracy.
                    `,
                    images:['https://firebasestorage.googleapis.com/v0/b/portfolio-b3f3a.appspot.com/o/images%2Fml-1.png?alt=media&token=44cd2e8a-ce63-4a11-8462-d400e1b115b2',
                    'https://firebasestorage.googleapis.com/v0/b/portfolio-b3f3a.appspot.com/o/images%2Fml-2.png?alt=media&token=c4bed346-72ac-4949-b21f-ccb5570563ae'],
                    github:'https://github.com/abhipray-cpu/ML',
                    demo:'https://colab.research.google.com/drive/10v-LkGn7U1Kp7aIudsZXmiQcJPQ8_Lw5',
                    type:0
                },
                //classification
                {
                    title:'Bank Customer Retention',
                    description:`This is a classification model notebook with end to end implementation,cleaning data,data visualization,
                    initializing multiple models,comparing them on different metics and then hypertuning the best model to achieve best
                    results and accuracy.`,
                    images:['https://firebasestorage.googleapis.com/v0/b/portfolio-b3f3a.appspot.com/o/images%2Fml-3.png?alt=media&token=28525546-aefb-405a-9ccd-edefbe421422',
                'https://firebasestorage.googleapis.com/v0/b/portfolio-b3f3a.appspot.com/o/images%2Fml-4.png?alt=media&token=552e9de0-611b-4ad8-a4a0-1005054b8ecb'],
                    github:'https://github.com/abhipray-cpu/ML',
                    demo:'https://colab.research.google.com/drive/12qSuXhSbdlAoix37ienXNO6PE3TGax89',
                    type:0
                },
                //ui
                {
                    title:'UI',
                    description:'Here are some UI and mockups I designed for my upcming projects check my figma for more such designs',
                    images:['https://firebasestorage.googleapis.com/v0/b/portfolio-b3f3a.appspot.com/o/images%2Fui-2.png?alt=media&token=debbea1e-2c35-4549-a2ad-b92fde5a062c','https://firebasestorage.googleapis.com/v0/b/portfolio-b3f3a.appspot.com/o/images%2Fui-1.png?alt=media&token=2d26f3fa-484b-4d2d-af05-405f721dec98'],
                    github:'https://www.figma.com/file/DAmaZDhALqpZZdf36SGnCM/Ecomerce-app?type=design&node-id=0%3A1&mode=design&t=u1F1t5aqLhKJ9UAX-1',
                    demo:'https://www.figma.com/proto/3QePLcribYFOH00lrtsizH/Cocktail-app-UI?page-id=0%3A1&type=design&node-id=80-51&viewport=1015%2C402%2C0.1&t=I6TGuhRdP2dIKkGF-1&scaling=scale-down&starting-point-node-id=80%3A51&show-proto-sidebar=1&mode=design',
                    type:0
                },
                //into the wild
                {
                    title:'Into The Wild',
                    description:`This is a website for my favorite movie INTO THE WILD which will tell you abot the real story and give you an insight on
                    how beautiful this movie actually is`,
                    images:['https://firebasestorage.googleapis.com/v0/b/portfolio-b3f3a.appspot.com/o/images%2Finto-the-wild-1.png?alt=media&token=e064a28b-b608-4b57-b386-5ff9d1c6e8c3',
                    'https://firebasestorage.googleapis.com/v0/b/portfolio-b3f3a.appspot.com/o/images%2Finto-the-wild-2.png?alt=media&token=6fed654e-2bdd-4264-99f2-b90790cd6e8e'],
                    github:'https://github.com/abhipray-cpu/Into-the-wild',
                    demo:'http://3.106.227.172/',
                    type:0
                },
            ]
        }
    },
}
</script>

<style scoped>
#fifth-page{
    width:100vw;
    height:fit-content;
    margin-top:0 !important;
    margin-bottom:0 !important;
    position: relative;

}
</style>