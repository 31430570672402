<template>
    <div id="second-mobile" :style="bg">
        <h2 :class="theme==0?'text-theme-1':theme==1?'text-theme-2':theme==2?'text-theme-3':'text-theme-4'">About Me</h2>
        <h3 :class="theme==1?'h3-theme-2':'h3-theme-1'">My Introduction</h3>
        <h3 :class="theme==1?'theme-2':'theme-1'">Beyond coding, I'm a freelance enthusiast, constantly honing skills and exploring new avenues. During downtime, I turn the mundane into magic, infusing creativity into every pursuit. Currently freelancing at Knk Soft Infotech, I bring innovation and dedication to shape digital realms.
        </h3>

       <div id="experience">
        <div id="exp-col-1" style="margin-left:4vw;">
            <h3 :class="theme==1?'exp-h3-2':'exp-h3-1'">01+</h3>
            <h4 :class="theme==1?'exp-h4-2':'exp-h4-1'">Years experience</h4>
        </div>
        <div id="exp-col-2" style="margin-left:3.5vw">
          <h3 :class="theme==1?'exp-h3-2':'exp-h3-1'">20+</h3>
          <h4 :class="theme==1?'exp-h4-2':'exp-h4-1'">Completed projects</h4>
        </div>
       </div>
       <div :class="theme==0?'pg-2-btn-1':theme==1?'pg-2-btn-2':theme==2?'pg-2-btn-3':'pg-2-btn-4'" @click="redirect">
        <h3>Download CV</h3>
        <svg xmlns="http://www.w3.org/2000/svg" width="17" height="18" viewBox="0 0 17 18" fill="none" v-if="theme==0">
            <path d="M12.5 6.60159C14.1675 6.61119 15.0706 6.68879 15.6594 7.30319C16.3333 8.00639 16.3333 9.13759 16.3333 11.4V12.2C16.3333 14.4632 16.3333 15.5944 15.6594 16.2976C14.9863 17 13.9015 17 11.7333 17H5.6C3.43187 17 2.34703 17 1.6739 16.2976C1 15.5936 1 14.4632 1 12.2V11.4C1 9.13759 1 8.00639 1.6739 7.30319C2.2627 6.68879 3.16583 6.61119 4.83333 6.60159" stroke="#344E41" stroke-linecap="round"/>
            <path d="M8.66665 1V11.4M8.66665 11.4L6.36665 8.6M8.66665 11.4L10.9667 8.6" stroke="#344E41" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
            <svg xmlns="http://www.w3.org/2000/svg" width="17" height="18" viewBox="0 0 17 18" fill="none" v-if="theme==1">
                <path d="M12.5 6.60156C14.1675 6.61116 15.0706 6.68876 15.6594 7.30316C16.3333 8.00636 16.3333 9.13756 16.3333 11.4V12.2C16.3333 14.4632 16.3333 15.5944 15.6594 16.2976C14.9863 17 13.9015 17 11.7333 17H5.6C3.43187 17 2.34703 17 1.6739 16.2976C1 15.5936 1 14.4632 1 12.2V11.4C1 9.13756 1 8.00636 1.6739 7.30316C2.2627 6.68876 3.16583 6.61116 4.83333 6.60156" stroke="#A3B18A" stroke-linecap="round"/>
                <path d="M8.66665 1V11.4M8.66665 11.4L6.36665 8.6M8.66665 11.4L10.9667 8.6" stroke="#A3B18A" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
                <svg xmlns="http://www.w3.org/2000/svg" width="17" height="18" viewBox="0 0 17 18" fill="none" v-if="theme==2">
                    <path d="M12.5 6.60156C14.1675 6.61116 15.0706 6.68876 15.6594 7.30316C16.3333 8.00636 16.3333 9.13756 16.3333 11.4V12.2C16.3333 14.4632 16.3333 15.5944 15.6594 16.2976C14.9863 17 13.9015 17 11.7333 17H5.6C3.43187 17 2.34703 17 1.6739 16.2976C1 15.5936 1 14.4632 1 12.2V11.4C1 9.13756 1 8.00636 1.6739 7.30316C2.2627 6.68876 3.16583 6.61116 4.83333 6.60156" stroke="#1D267D" stroke-linecap="round"/>
                    <path d="M8.6667 1V11.4M8.6667 11.4L6.3667 8.6M8.6667 11.4L10.9667 8.6" stroke="#1D267D" stroke-linecap="round" stroke-linejoin="round"/>
                  </svg>
                  <svg xmlns="http://www.w3.org/2000/svg" width="17" height="18" viewBox="0 0 17 18" fill="none" v-if="theme==3">
                    <path d="M12.5 6.60156C14.1675 6.61116 15.0706 6.68876 15.6594 7.30316C16.3333 8.00636 16.3333 9.13756 16.3333 11.4V12.2C16.3333 14.4632 16.3333 15.5944 15.6594 16.2976C14.9863 17 13.9015 17 11.7333 17H5.6C3.43187 17 2.34703 17 1.6739 16.2976C1 15.5936 1 14.4632 1 12.2V11.4C1 9.13756 1 8.00636 1.6739 7.30316C2.2627 6.68876 3.16583 6.61116 4.83333 6.60156" stroke="#1D267D" stroke-linecap="round"/>
                    <path d="M8.6667 1V11.4M8.6667 11.4L6.3667 8.6M8.6667 11.4L10.9667 8.6" stroke="#1D267D" stroke-linecap="round" stroke-linejoin="round"/>
                  </svg>
       </div>
    </div>
</template>

<script>
export default {
    methods:{

redirect(){
    window.open('https://firebasestorage.googleapis.com/v0/b/portfolio-b3f3a.appspot.com/o/Abhipray-Dumka.pdf?alt=media&token=488de2ec-6491-4beb-9333-681ea386cb86','_blank')
}
},
    computed:{
        bg(){
            let theme = this.$store.getters['getTheme']
            if(theme==0){
                return `background:#344E41`
            }
            else if(theme==1){
                return `background:#A3B18A`
            }
            else if(theme==2){
              return 'background:#0C134F'
            }
            else if(theme==3){
              return 'background:#1D267D'
            }
            else{
              return `background:#344E41`
            }
        },
        text(){
            let theme = this.$store.getters['getTheme']
            if(theme==0){
                return `color:#344E41`
            }
            else{
                return `color:`
            }
        },
        theme(){
            return this.$store.getters['getTheme']
        },
    }
}
</script>

<style scoped>
@media only screen and (max-width:480px){
    #second-mobile{
        width:100vw;
    height: fit-content;
    overflow-x: hidden;
    overflow-y:auto;
    position: relative;
    margin:0 !important;
    z-index: 0;
    padding-top:2vh;
    }
.text-theme-1{
    color: #A3B18A;
font-family: Roboto;
font-size: 28px;
font-style: normal;
font-weight: 700;
line-height: normal;
text-align:center;
margin-bottom:0px;
}
.text-theme-2{
    color: #344E41;
    font-family: Roboto;
    font-size: 28px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    text-align: center;
    margin-bottom:0px;
}
.text-theme-3{
    color: #5C469C;
    font-family: Roboto;
    font-size: 28px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    text-align: center;
    margin-bottom:0px;
}
.text-theme-4{
    color: #D4ADFC;
    font-family: Roboto;
    font-size: 28px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    text-align: center;
    margin-bottom:0px;
}
.h3-theme-1{
    color: #C8CFCC;
    font-family: Roboto;
    font-size: 17px;
    font-style: normal;
    font-weight: 350;
    line-height: normal;
    text-align: center;
    margin-top:2px;
}
.h3-theme-2{
    color: #588157;
font-family: Roboto;
font-size: 17px;
font-style: normal;
font-weight: 400;
line-height: normal;
    margin-top:2px;
    text-align: center;
}
.theme-1{
    color: rgba(255, 255, 255, 0.73);;
text-align: center;
font-family: Roboto;
font-size: 24px;
font-style: normal;
font-weight: 400;
line-height: normal;
text-align: center;
width:80vw;
margin-left:10vw;
}
.theme-2{
    color: #588157;
    text-align: center;
    font-family: Roboto;
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-align: center;
width:80vw;
margin-left:10vw;
}
#ul{
    margin-left:3.2vw;
    list-style-type:none;
    
}
.li-theme-1{
    color: rgba(255, 255, 255, 0.73);
font-family: Roboto;
font-size: 22px;
font-style: normal;
font-weight: 400;
line-height: normal;
margin-top:1.5vh;
margin-bottom:1.5vh;
width:80vw;
text-align:center
}
.li-theme-2{
    color: #588157;
    font-family: Roboto;
    font-size: 22px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-top:1.5vh;
margin-bottom:1.5vh;
width:80vw;
text-align:center;
}
#experience{
    margin-top:2.5vh;
    display: grid;
    width: 80vw;
    margin-left:10vw;
    grid-template-columns: repeat(2,40vw);
}
#exp-col-1{
    width:40vw;
}
#exp-col-2{
    width:40vw;
}
.exp-h3-1{color: rgba(255, 255, 255, 0.83);
    font-family: Inter;
    font-size: 28px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin-left:10vw;
    margin-bottom:0px;
}
.exp-h3-2{
    color: #3A5A40;
font-family: Inter;
font-size: 28px;
font-style: normal;
font-weight: 700;
line-height: normal;
margin-left:10vw;
margin-bottom:0px;
}
.exp-h4-1{
    color: rgba(255, 255, 255, 0.53);
font-family: Inter;
font-size: 18px;
font-style: normal;
font-weight: 100;
margin-top:2px;
}
.exp-h4-2{
    color: #588157;
font-family: Inter;
font-size: 18px;
font-style: normal;
font-weight: 200;
line-height: normal;
margin-top:2px;
}
.pg-2-btn-1{
    width:50vw;
    margin-left:25vw;
    height:8vh;
    border-radius: 10px;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    background: #A3B18A;
}
.pg-2-btn-1 h3{
    color: #344E41;
font-family: Inter;
font-size: 20px;
font-style: normal;
font-weight: 600;
line-height: 8vh;
margin-top:0px;
margin-left:4vw;
float:left
}
.pg-2-btn-1 svg,
.pg-2-btn-2 svg,
.pg-2-btn-3 svg,
.pg-2-btn-4 svg
{
    float:right;
    margin-right:9vw;
    margin-top:2.8vh;
}
.pg-2-btn-2{
    width:50vw;
    margin-left:25vw;
    height:8vh;
    border-radius: 10px;
background: #344E41;
box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
}
.pg-2-btn-2 h3{
    color: #A3B18A;
font-family: Inter;
font-size: 20px;
font-style: normal;
    font-weight: 600;
    line-height: 8vh;
    margin-top:0px;
    margin-left:4vw;
    float:left
}
.pg-2-btn-3{
    width:50vw;
    margin-left:25vw;
    height:8vh;
    border-radius: 10px;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    background: #D4ADFC;
}
.pg-2-btn-3 h3{
    color: #1D267D;
font-family: Inter;
font-size: 20px;
font-style: normal;
font-weight: 600;
line-height: 8vh;
margin-top:0px;
margin-left:4vw;
float:left
}
.pg-2-btn-4{
    width:50vw;
    margin-left:25vw;
    height:8vh;
    border-radius: 10px;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    background: #A2A1B5;
}
.pg-2-btn-4 h3{
    color: #1D267D;
font-family: Inter;
font-size: 20px;
font-style: normal;
font-weight: 600;
line-height: 8vh;
margin-top:0px;
margin-left:4vw;
float:left
}
}
@media only screen and (min-width:481px) and (max-width:960px){
    #second-mobile{
        display: none;
    }
}
@media only screen and (min-width:961px){
    #second-mobile{
        display: none;
    }
}

</style>