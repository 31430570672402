<template>
    <div id="sixth-page">
        <desktop-view @go-to="goto"></desktop-view>
        <mobile-view @go-to="goto"></mobile-view>
    </div>
</template>

<script>
import DesktopView from './desktop-view.vue'
import MobileView from './mobile-view.vue'
export default {
    components:{DesktopView,MobileView},
    methods:{
        goto(val){
            this.$emit('go-to',val)
        }
    }
}
</script>

<style scoped>
#sixth-page{
    width:100vw;
    height:fit-content;
    margin-top:0 !important;
    position: relative;


}
</style>