<template>
    <div id="third-page">
        <desktop-view></desktop-view>
        <mobile-view></mobile-view>
    </div>
</template>

<script>
import DesktopView from './desktop-view.vue'
import MobileView from './mobile-view.vue'
export default {
    components:{DesktopView,MobileView}
}
</script>

<style scoped>
#third-page{
    width:100vw;
    height:fit-content;
    margin-top:-0.3vh!important;
    margin-bottom:0 !important;
    position: relative;

}
</style>