<template>
    <div id="fifth-mobile" :style="bg">
      <h2 :class="theme==0?'text-theme-1':theme==1?'text-theme-2':'text-theme-3'">Portfolio</h2>
         <h3 :class="theme==0?'h3-theme-1':theme==1?'h3-theme-2':'h3-theme-3'">My work</h3>
        <div class="carousel-container">
            <div class="slides" v-for="item,index in projects" :key="index">
                <div class="slide">
                    <div class="slide">
                        <div id="carousel-image" >
                            <img :src="item['images'][0]" :alt="item['title']" :class="theme==0?'car-img-1':theme==1?'car-img-2':'car-img-3'">
                        </div>
                        <div id="carousel-text">
                            <h2 :class="theme==0?'title-1':theme==1?'title-2':'title-3'">{{ item['title'] }}</h2>
                            <div style="width:80vw;margin-left:10vw;">
                                <h3 :class="theme==0?'description-1':theme==1?'description-2':'description-3'">{{ item['description'] }}</h3>
                            </div>
                        </div>
                        <div id="carousel-buttons">
                            <div :class="theme==0?'car-btn-1':theme==1?'car-btn-2':'car-btn-3'" @click="goto(item['github'])">
                                <h3>Github</h3>
                                <svg xmlns="http://www.w3.org/2000/svg" width="13" height="12" viewBox="0 0 13 12" fill="none" v-if="theme==0">
                                    <path d="M6.98677 11.748C6.91407 11.6684 6.85638 11.5738 6.81702 11.4696C6.77766 11.3655 6.7574 11.2538 6.7574 11.141C6.7574 11.0282 6.77766 10.9166 6.81702 10.8124C6.85638 10.7083 6.91407 10.6137 6.98677 10.534C8.27128 9.12265 7.26989 6.85729 5.36149 6.85729L0.779847 6.85729C0.573019 6.85729 0.374662 6.76701 0.228413 6.60631C0.0821629 6.44562 9.53674e-07 6.22766 9.53674e-07 6.0004C9.53674e-07 5.77314 0.0821629 5.55519 0.228413 5.39449C0.374662 5.2338 0.573019 5.14352 0.779847 5.14352L5.36043 5.14352C7.26954 5.14352 8.27148 2.8775 6.98677 1.46533C6.84027 1.30436 6.75796 1.08603 6.75796 0.858373C6.75796 0.630718 6.84027 0.412388 6.98677 0.251412C7.13327 0.0904355 7.33198 2.39873e-09 7.53916 0C7.74635 -2.39873e-09 7.94505 0.0904355 8.09155 0.251412L12.7706 5.39273C12.8433 5.47234 12.901 5.56693 12.9404 5.67109C12.9797 5.77524 13 5.88691 13 5.99969C13 6.11247 12.9797 6.22414 12.9404 6.32829C12.901 6.43245 12.8433 6.52704 12.7706 6.60665L8.09155 11.748C8.0191 11.8279 7.93301 11.8912 7.83822 11.9345C7.74343 11.9777 7.6418 12 7.53916 12C7.43652 12 7.33489 11.9777 7.2401 11.9345C7.14531 11.8912 7.05922 11.8279 6.98677 11.748Z" fill="#A3B18A"/>
                                  </svg>
                                  <svg xmlns="http://www.w3.org/2000/svg" width="13" height="12" viewBox="0 0 13 12" fill="none" v-if="theme==1">
                                    <path d="M6.98677 11.748C6.91407 11.6684 6.85638 11.5738 6.81702 11.4696C6.77766 11.3655 6.7574 11.2538 6.7574 11.141C6.7574 11.0282 6.77766 10.9166 6.81702 10.8124C6.85638 10.7083 6.91407 10.6137 6.98677 10.534C8.27128 9.12265 7.26989 6.85729 5.36149 6.85729L0.779847 6.85729C0.573019 6.85729 0.374662 6.76701 0.228413 6.60631C0.0821629 6.44562 9.53674e-07 6.22766 9.53674e-07 6.0004C9.53674e-07 5.77314 0.0821629 5.55519 0.228413 5.39449C0.374662 5.2338 0.573019 5.14352 0.779847 5.14352L5.36043 5.14352C7.26954 5.14352 8.27148 2.8775 6.98677 1.46533C6.84027 1.30436 6.75796 1.08603 6.75796 0.858373C6.75796 0.630718 6.84027 0.412388 6.98677 0.251412C7.13327 0.0904355 7.33198 2.39873e-09 7.53916 0C7.74635 -2.39873e-09 7.94505 0.0904355 8.09155 0.251412L12.7706 5.39273C12.8433 5.47234 12.901 5.56693 12.9404 5.67109C12.9797 5.77524 13 5.88691 13 5.99969C13 6.11247 12.9797 6.22414 12.9404 6.32829C12.901 6.43245 12.8433 6.52704 12.7706 6.60665L8.09155 11.748C8.0191 11.8279 7.93301 11.8912 7.83822 11.9345C7.74343 11.9777 7.6418 12 7.53916 12C7.43652 12 7.33489 11.9777 7.2401 11.9345C7.14531 11.8912 7.05922 11.8279 6.98677 11.748Z" fill="#344E41"/>
                                  </svg>
                                  <svg xmlns="http://www.w3.org/2000/svg" width="13" height="12" viewBox="0 0 13 12" fill="none" v-if="theme==2 || theme==3 ">
                                    <path d="M6.98677 11.748C6.91407 11.6684 6.85638 11.5738 6.81702 11.4696C6.77766 11.3655 6.7574 11.2538 6.7574 11.141C6.7574 11.0282 6.77766 10.9166 6.81702 10.8124C6.85638 10.7082 6.91407 10.6137 6.98677 10.534V10.534C8.27127 9.12265 7.26989 6.85729 5.36149 6.85729L0.779847 6.85729C0.573019 6.85729 0.374662 6.76701 0.228413 6.60631C0.0821631 6.44562 6.22883e-07 6.22766 6.22883e-07 6.0004C6.22883e-07 5.77314 0.0821631 5.55519 0.228413 5.39449C0.374662 5.2338 0.573019 5.14352 0.779847 5.14352L5.36043 5.14352C7.26954 5.14352 8.27148 2.8775 6.98677 1.46533V1.46533C6.84027 1.30436 6.75796 1.08603 6.75796 0.858373C6.75796 0.630718 6.84027 0.412388 6.98677 0.251412C7.13327 0.0904355 7.33198 2.39873e-09 7.53916 0C7.74635 -2.39873e-09 7.94505 0.0904355 8.09155 0.251412L12.7706 5.39273C12.8433 5.47234 12.901 5.56693 12.9404 5.67109C12.9797 5.77524 13 5.88691 13 5.99969C13 6.11247 12.9797 6.22414 12.9404 6.32829C12.901 6.43245 12.8433 6.52704 12.7706 6.60665L8.09155 11.748C8.0191 11.8279 7.93301 11.8912 7.83822 11.9345C7.74343 11.9777 7.6418 12 7.53916 12C7.43652 12 7.33489 11.9777 7.2401 11.9345C7.14531 11.8912 7.05922 11.8279 6.98677 11.748Z" fill="#D4ADFC"/>
                                  </svg>
                            </div>
                            <div :class="theme==0?'car-btn-1':theme==1?'car-btn-2':'car-btn-3'" @click="goto(item['demo'])">
                                <h3>Demo</h3>
                                <svg xmlns="http://www.w3.org/2000/svg" width="13" height="12" viewBox="0 0 13 12" fill="none" v-if="theme==0">
                                    <path d="M6.98677 11.748C6.91407 11.6684 6.85638 11.5738 6.81702 11.4696C6.77766 11.3655 6.7574 11.2538 6.7574 11.141C6.7574 11.0282 6.77766 10.9166 6.81702 10.8124C6.85638 10.7083 6.91407 10.6137 6.98677 10.534C8.27128 9.12265 7.26989 6.85729 5.36149 6.85729L0.779847 6.85729C0.573019 6.85729 0.374662 6.76701 0.228413 6.60631C0.0821629 6.44562 9.53674e-07 6.22766 9.53674e-07 6.0004C9.53674e-07 5.77314 0.0821629 5.55519 0.228413 5.39449C0.374662 5.2338 0.573019 5.14352 0.779847 5.14352L5.36043 5.14352C7.26954 5.14352 8.27148 2.8775 6.98677 1.46533C6.84027 1.30436 6.75796 1.08603 6.75796 0.858373C6.75796 0.630718 6.84027 0.412388 6.98677 0.251412C7.13327 0.0904355 7.33198 2.39873e-09 7.53916 0C7.74635 -2.39873e-09 7.94505 0.0904355 8.09155 0.251412L12.7706 5.39273C12.8433 5.47234 12.901 5.56693 12.9404 5.67109C12.9797 5.77524 13 5.88691 13 5.99969C13 6.11247 12.9797 6.22414 12.9404 6.32829C12.901 6.43245 12.8433 6.52704 12.7706 6.60665L8.09155 11.748C8.0191 11.8279 7.93301 11.8912 7.83822 11.9345C7.74343 11.9777 7.6418 12 7.53916 12C7.43652 12 7.33489 11.9777 7.2401 11.9345C7.14531 11.8912 7.05922 11.8279 6.98677 11.748Z" fill="#A3B18A"/>
                                  </svg>
                                  <svg xmlns="http://www.w3.org/2000/svg" width="13" height="12" viewBox="0 0 13 12" fill="none" v-if="theme==1">
                                    <path d="M6.98677 11.748C6.91407 11.6684 6.85638 11.5738 6.81702 11.4696C6.77766 11.3655 6.7574 11.2538 6.7574 11.141C6.7574 11.0282 6.77766 10.9166 6.81702 10.8124C6.85638 10.7083 6.91407 10.6137 6.98677 10.534C8.27128 9.12265 7.26989 6.85729 5.36149 6.85729L0.779847 6.85729C0.573019 6.85729 0.374662 6.76701 0.228413 6.60631C0.0821629 6.44562 9.53674e-07 6.22766 9.53674e-07 6.0004C9.53674e-07 5.77314 0.0821629 5.55519 0.228413 5.39449C0.374662 5.2338 0.573019 5.14352 0.779847 5.14352L5.36043 5.14352C7.26954 5.14352 8.27148 2.8775 6.98677 1.46533C6.84027 1.30436 6.75796 1.08603 6.75796 0.858373C6.75796 0.630718 6.84027 0.412388 6.98677 0.251412C7.13327 0.0904355 7.33198 2.39873e-09 7.53916 0C7.74635 -2.39873e-09 7.94505 0.0904355 8.09155 0.251412L12.7706 5.39273C12.8433 5.47234 12.901 5.56693 12.9404 5.67109C12.9797 5.77524 13 5.88691 13 5.99969C13 6.11247 12.9797 6.22414 12.9404 6.32829C12.901 6.43245 12.8433 6.52704 12.7706 6.60665L8.09155 11.748C8.0191 11.8279 7.93301 11.8912 7.83822 11.9345C7.74343 11.9777 7.6418 12 7.53916 12C7.43652 12 7.33489 11.9777 7.2401 11.9345C7.14531 11.8912 7.05922 11.8279 6.98677 11.748Z" fill="#344E41"/>
                                  </svg>
                                  <svg xmlns="http://www.w3.org/2000/svg" width="13" height="12" viewBox="0 0 13 12" fill="none" v-if="theme==2 || theme==3 ">
                                    <path d="M6.98677 11.748C6.91407 11.6684 6.85638 11.5738 6.81702 11.4696C6.77766 11.3655 6.7574 11.2538 6.7574 11.141C6.7574 11.0282 6.77766 10.9166 6.81702 10.8124C6.85638 10.7082 6.91407 10.6137 6.98677 10.534V10.534C8.27127 9.12265 7.26989 6.85729 5.36149 6.85729L0.779847 6.85729C0.573019 6.85729 0.374662 6.76701 0.228413 6.60631C0.0821631 6.44562 6.22883e-07 6.22766 6.22883e-07 6.0004C6.22883e-07 5.77314 0.0821631 5.55519 0.228413 5.39449C0.374662 5.2338 0.573019 5.14352 0.779847 5.14352L5.36043 5.14352C7.26954 5.14352 8.27148 2.8775 6.98677 1.46533V1.46533C6.84027 1.30436 6.75796 1.08603 6.75796 0.858373C6.75796 0.630718 6.84027 0.412388 6.98677 0.251412C7.13327 0.0904355 7.33198 2.39873e-09 7.53916 0C7.74635 -2.39873e-09 7.94505 0.0904355 8.09155 0.251412L12.7706 5.39273C12.8433 5.47234 12.901 5.56693 12.9404 5.67109C12.9797 5.77524 13 5.88691 13 5.99969C13 6.11247 12.9797 6.22414 12.9404 6.32829C12.901 6.43245 12.8433 6.52704 12.7706 6.60665L8.09155 11.748C8.0191 11.8279 7.93301 11.8912 7.83822 11.9345C7.74343 11.9777 7.6418 12 7.53916 12C7.43652 12 7.33489 11.9777 7.2401 11.9345C7.14531 11.8912 7.05922 11.8279 6.98677 11.748Z" fill="#D4ADFC"/>
                                  </svg>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data(){
        return{
            curr:0
        }
    },
    methods:{
        goToCurr(val){
            this.curr=val;
        },
        goto(val){
        window.open(val, '_blank');

      },
    },
    computed:{
        Curr(){
            return this.curr;
        },
        bg(){
            let theme = this.$store.getters['getTheme']
            if(theme==0){
              return `background:#A3B18A`
               
            }
            else if(theme==1){
              return `background:#344E41`
            }
            else if(theme==2){
              return 'background:#D4ADFC;'
            }
            else if(theme==3){
              return 'background:#D4ADFC;'
            }
            else{
              return `background:#A3B18A`
            }
            
        },
        text(){
            let theme = this.$store.getters['getTheme']
            if(theme==0){
                return `color:#344E41`
            }
            else{
                return `color:`
            }
        },
        theme(){
            return this.$store.getters['getTheme']
        },
    },
    props:['projects'] 
}
</script>

<style scoped>
@media only screen and (max-width:480px){
#fifth-mobile{
    width:100vw;
        overflow-x: hidden;
        overflow-y:auto;
        position: relative;
        margin-left:0 !important;
       z-index: 0;
       padding-bottom:2vh;

}
.text-theme-1{
  color: #344E41;;
font-family: Roboto;
font-size: 28px;
font-style: normal;
font-weight: 700;
line-height: normal;
text-align:center;
margin-bottom:0px;
}
.text-theme-2{
  color: #A3B18A;
  font-family: Roboto;
  font-size: 28px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-align: center;
  margin-bottom:0px;
}
.text-theme-3{
  color: #0C134F;
  font-family: Roboto;
  font-size: 28px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-align: center;
  margin-bottom:0px;
}
.h3-theme-3{
  color: #1d277dc8;
  font-family: Roboto;
  font-size: 17px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
    margin-top:2px;
    text-align: center;
}
.h3-theme-1{
  color: #588157;
  font-family: Roboto;
  font-size: 17px;
  font-style: normal;
  font-weight: 350;
  line-height: normal;
  text-align: center;
  margin-top:2px;
}
.h3-theme-2{
  color: #C8CFCC;
font-family: Roboto;
font-size: 17px;
font-style: normal;
font-weight: 400;
line-height: normal;
  margin-top:2px;
  text-align: center;
}
.carousel-container{
    width:100vw;
    display: flex;
    flex-direction: row;
    overflow-x: auto;
    padding:0px;
    overflow-y:hidden ;
}
.carousel-container::-webkit-scrollbar{
    display: none;
}
.slides{
    width:100vw;
    height: fit-content;
    margin:0;
    padding:0
}
.slide{
width:100vw;
height: fit-conte;
margin:0;
padding:0
}
#carousel-image{
    width:100vw;
    height:20vh;
  }
  #carousel-image img{
    width:70vw;
    height:20vh;
    margin-left:15vw;
    border-radius: 5px;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);

  }
  .car-img-1{
border:2px solid #344E41
  }
  .car-img-2{
  border:2px solid #A3B18A;
  }
  .car-img-3{
    border:2px solid #0C134F;
    }
  #carousel-text{
    margin-top:1vh;
  }
  .title-1{
text-align: center;
  overflow-x: hidden;
  color: #344E41;
text-align: center;
font-family: Roboto;
font-size: 28px;
font-style: normal;
font-weight: 400;
line-height: normal;
  }
  .description-1{
    color: #588157;
    font-family: Roboto;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    text-align: center;
  }
  .title-2{
    text-align: center;
      overflow-x: hidden;
      color: #A3B18A;
    text-align: center;
    font-family: Roboto;
    font-size: 28px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
      }
      .description-2{
        color: #DAD7CD;
        font-family: Roboto;
        font-size: 18px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        text-align: center;
      }
      #carousel-buttons{
        padding-left:15vw;
        padding-top:2vh;
        display: flex;
        flex-direction: row;
      }
      .car-btn-1{
        width:30vw;
        height:6vh;
        border-radius: 5px;
    background: #3A5A40;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    margin-left:3vw;
    margin-right:3vw;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap:0.75vw;
      }
      .car-btn-1 h3{
        color: #A3B18A;
    text-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    font-family: Roboto;
    font-size: 27px;
    font-style: normal;
    font-weight: 400;
    line-height:normal;
    margin-right:0.75vw;
      }
      .car-btn-2{
        width:30vw;
        height:6vh;
        border-radius: 5px;
background: #A3B18A;
box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    margin-left:3vw;
    margin-right:3vw;
    display: flex;
    justify-content: center;
    align-items: center;
    padding:0px;
    gap:0.75vw;
      }
      .car-btn-2 h3{
        color: #344E41;;
    text-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    font-family: Roboto;
    font-size: 27px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-right:0.75vw;

      }
      
        .title-3{
            color: #0C134F;
            text-align: center;
  overflow-x: hidden;
text-align: center;
font-family: Roboto;
font-size: 28px;
font-style: normal;
font-weight: 400;
line-height: normal;
            }
            .description-3{
              color: #1D267D;
              font-family: Roboto;
        font-size: 18px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        text-align: center;
            }
        .car-btn-3{
          width:30vw;
          height:6vh;
          border-radius: 5px;
          background: #0C134F;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
      margin-left:3vw;
      margin-right:3vw;
      display: flex;
      justify-content: center;
      align-items: center;
      padding:0px;
      gap:0.75vw;
        }
        .car-btn-3 h3{
          color: #D4ADFC;
      text-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
      font-family: Roboto;
      font-size: 32px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      margin-right:0.75vw;
  
        }
}
@media only screen and (min-width:481px) and (max-width:960px){
  #fifth-mobile{
      width:100vw;
          overflow-x: hidden;
          overflow-y:auto;
          position: relative;
          margin-left:0 !important;
         z-index: 0;
         padding-bottom:2vh;
  
  }
  .text-theme-1{
    color: #344E41;;
  font-family: Roboto;
  font-size: 38px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-align:center;
  margin-bottom:0px;
  }
  .text-theme-2{
    color: #A3B18A;
    font-family: Roboto;
    font-size: 38px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    text-align: center;
    margin-bottom:0px;
  }
  .text-theme-3{
    color: #0C134F;
    font-family: Roboto;
    font-size: 38px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    text-align: center;
    margin-bottom:0px;
  }
  .h3-theme-1{
    color: #588157;
    font-family: Roboto;
    font-size: 25px;
    font-style: normal;
    font-weight: 350;
    line-height: normal;
    text-align: center;
    margin-top:2px;
  }
  .h3-theme-2{
    color: #C8CFCC;
  font-family: Roboto;
  font-size: 25px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
    margin-top:2px;
    text-align: center;
  }
  .h3-theme-3{
    color: #5C469C;
  font-family: Roboto;
  font-size: 25px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
    margin-top:2px;
    text-align: center;
  }
  
  .carousel-container{
      width:100vw;
      display: flex;
      flex-direction: row;
      overflow-x: auto;
      padding:0px;
      overflow-y:hidden ;
  }
  .carousel-container::-webkit-scrollbar{
      display: none;
  }
  .slides{
      width:100vw;
      height: fit-content;
      margin:0;
      padding:0
  }
  .slide{
  width:100vw;
  height: fit-conte;
  margin:0;
  padding:0
  }
  #carousel-image{
      width:100vw;
    }
    #carousel-image img{
      width:80vw;
      height:35vh;
      margin-left:10vw;
      border-radius: 5px;
      box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  
    }
    .car-img-1{
  border:2px solid #344E41
    }
    .car-img-2{
    border:2px solid #A3B18A;
    }
    .car-img-3{
      border:2px solid #0C134F;
      }
    #carousel-text{
      margin-top:1vh;
    }
    .title-1{
  text-align: center;
    overflow-x: hidden;
    color: #344E41;
  text-align: center;
  font-family: Roboto;
  font-size: 38px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
    }
    .description-1{
      color: #588157;
      font-family: Roboto;
      font-size: 28px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      text-align: center;
    }
    .title-2{
      text-align: center;
        overflow-x: hidden;
        color: #A3B18A;
      text-align: center;
      font-family: Roboto;
      font-size: 38px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
        }
        .description-2{
          color: #DAD7CD;
          font-family: Roboto;
          font-size: 28px;
          font-style: normal;
          font-weight: 500;
          line-height: normal;
          text-align: center;
        }

        .title-2{
          text-align: center;
            overflow-x: hidden;
            color: #A3B18A;
          text-align: center;
          font-family: Roboto;
          font-size: 38px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
            }
            .description-2{
              color: #DAD7CD;
              font-family: Roboto;
              font-size: 28px;
              font-style: normal;
              font-weight: 500;
              line-height: normal;
              text-align: center;
            }
            .title-3{
              text-align: center;
                overflow-x: hidden;
                color: #0C134F;
              text-align: center;
              font-family: Roboto;
              font-size: 38px;
              font-style: normal;
              font-weight: 400;
              line-height: normal;
                }
                .description-3{
                  color: #0c134fd6;
                  font-family: Roboto;
                  font-size: 28px;
                  font-style: normal;
                  font-weight: 500;
                  line-height: normal;
                  text-align: center;
                }
        #carousel-buttons{
          padding-left:15vw;
          padding-top:2vh;
          display: flex;
          flex-direction: row;
        }
        .car-btn-1{
          width:30vw;
          height:6vh;
          border-radius: 5px;
      background: #3A5A40;
      box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
      margin-left:3vw;
      margin-right:3vw;
      border-radius: 5px;
      display: flex;
      justify-content: center;
      align-items: center;
      gap:0.75vw;
        }
        .car-btn-1 h3{
          color: #A3B18A;
      text-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
      font-family: Roboto;
      font-size: 32px;
      font-style: normal;
      font-weight: 400;
      line-height:normal;
      margin-right:0.75vw;
        }
        .car-btn-2{
          width:30vw;
          height:6vh;
          border-radius: 5px;
  background: #A3B18A;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
      margin-left:3vw;
      margin-right:3vw;
      display: flex;
      justify-content: center;
      align-items: center;
      padding:0px;
      gap:0.75vw;
        }
        .car-btn-2 h3{
          color: #344E41;;
      text-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
      font-family: Roboto;
      font-size: 32px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      margin-right:0.75vw;
  
        }
        .car-btn-3{
          width:30vw;
          height:6vh;
          border-radius: 5px;
  background: #0C134F ;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
      margin-left:3vw;
      margin-right:3vw;
      display: flex;
      justify-content: center;
      align-items: center;
      padding:0px;
      gap:0.75vw;
        }
        .car-btn-3 h3{
          color: #D4ADFC;;
      text-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
      font-family: Roboto;
      font-size: 32px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      margin-right:0.75vw;
  
        }
       
  }
@media only screen and (min-width:961px){
    #fifth-mobile{
        display: none;
    }
}
</style>