<template>
    <div id="header" :style="bg">
    <div>
        <h2 id="dumka">Dumka</h2>
    </div>
    <div id="nav">
       <h3 :class="[theme==0?'text-theme-1':'text-theme-2',currPage == 0 ?'active':'passive']"  @click="$emit('go-to','first-page')" :style="text1">Home</h3>
       <h3 :class="[theme==0?'text-theme-1':'text-theme-2',currPage == 0 ?'active':'passive']" @click="$emit('go-to','second-page')" :style="text2">About</h3>
       <h3 :class="[theme==0?'text-theme-1':'text-theme-2',currPage == 0 ?'active':'passive']" @click="$emit('go-to','third-page')" :style="text3">Skills</h3>
       <h3 :class="[theme==0?'text-theme-1':'text-theme-2',currPage == 0 ?'active':'passive']" @click="$emit('go-to','fourth-page')" :style="text4">Qualification</h3>
       <h3 :class="[theme==0?'text-theme-1':'text-theme-2',currPage == 0 ?'active':'passive']" @click="$emit('go-to','fifth-page')" :style="text5">Work</h3>
       <h3 :class="[theme==0?'text-theme-1':'text-theme-2',currPage == 0 ?'active':'passive']" @click="$emit('go-to','sixth-page')" :style="text6">Contact</h3>
       <svg xmlns="http://www.w3.org/2000/svg" width="25" height="26" viewBox="0 0 25 26" fill="none" v-if="theme==1" @click="changeTheme">
        <path d="M16.6667 23.6364H19.4444V21.2727H16.6667V23.6364ZM22.2222 9.45455H25V7.09091H22.2222V9.45455ZM0 4.72727V21.2727C0 22.5727 1.25 23.6364 2.77778 23.6364H8.33333V21.2727H2.77778V4.72727H8.33333V2.36364H2.77778C1.25 2.36364 0 3.42727 0 4.72727ZM22.2222 2.36364V4.72727H25C25 3.42727 23.75 2.36364 22.2222 2.36364ZM11.1111 26H13.8889V0H11.1111V26ZM22.2222 18.9091H25V16.5455H22.2222V18.9091ZM16.6667 4.72727H19.4444V2.36364H16.6667V4.72727ZM22.2222 14.1818H25V11.8182H22.2222V14.1818ZM22.2222 23.6364C23.75 23.6364 25 22.5727 25 21.2727H22.2222V23.6364Z" fill="#588157"/>
        </svg>
       <svg xmlns="http://www.w3.org/2000/svg" width="25" height="26" viewBox="0 0 25 26" fill="none" v-else @click="changeTheme">
        <path d="M16.6667 23.6364H19.4444V21.2727H16.6667V23.6364ZM22.2222 9.45455H25V7.09091H22.2222V9.45455ZM0 4.72727V21.2727C0 22.5727 1.25 23.6364 2.77778 23.6364H8.33333V21.2727H2.77778V4.72727H8.33333V2.36364H2.77778C1.25 2.36364 0 3.42727 0 4.72727ZM22.2222 2.36364V4.72727H25C25 3.42727 23.75 2.36364 22.2222 2.36364ZM11.1111 26H13.8889V0H11.1111V26ZM22.2222 18.9091H25V16.5455H22.2222V18.9091ZM16.6667 4.72727H19.4444V2.36364H16.6667V4.72727ZM22.2222 14.1818H25V11.8182H22.2222V14.1818ZM22.2222 23.6364C23.75 23.6364 25 22.5727 25 21.2727H22.2222V23.6364Z" fill="#DAD7CD"/>
        </svg>
        
    </div>
    </div>
</template>

<script>
export default {
    computed:{
        bg(){
            let theme = this.$store.getters['getTheme']
            if(theme==0){
                return `background:#344E41`
            }
            else if(theme==1){
                return `background:#A3B18A`
            }
            else if(theme==2){
              return 'background:#0C134F'
            }
            else if(theme==3){
              return 'background:#1D267D'
            }
            else{
              return `background:#344E41`
            }
        },
        text(){
            let theme = this.$store.getters['getTheme']
            if(theme==0){
                return `color:#344E41`
            }
            else{
                return `color:`
            }
        },
        theme(){
            return this.$store.getters['getTheme']
        },
        text1(){
            let page = this.$store.getters['getPage']
            let theme = this.$store.getters['getTheme']
            if(page == 0 && theme == 0){
                return `color:#A3B18A`
            }
            if(page == 0 && theme == 1){
                return `color:#3A5A40`
            }
            if(page == 0 && (theme == 2 || theme==3)){
                return `color:#D4ADFC`
                
            }
            if(page != 0 && theme == 0){
                return `color:#DAD7CD`
                
            }
            if(page != 0 && theme == 1){
                return `color:#588157`
            }
            if(page != 0 && (theme == 2 || theme==3)){
                return `color:#F8F7F6;`
            }
            else
            return `color:#A3B18A`
        },
        text2(){
            let page = this.$store.getters['getPage']
            let theme = this.$store.getters['getTheme']
            if(page == 1 && theme == 0){
                return `color:#A3B18A`
            }
            if(page == 1 && theme == 1){
                return `color:#3A5A40`
            }
            if(page == 1 && (theme == 2 || theme==3)){
                return `color:#D4ADFC`
                
            }
            if(page != 1 && theme == 0){
                return `color:#DAD7CD`
                
            }
            if(page != 1 && theme == 1){
                return `color:#588157`
            }
            if(page != 1 && (theme == 2 || theme==3)){
                return `color:#F8F7F6;`
            }
            else
            return `color:#A3B18A`
        },
        text3(){
            let page = this.$store.getters['getPage']
            let theme = this.$store.getters['getTheme']
            if(page == 2 && theme == 0){
                return `color:#A3B18A`
            }
            if(page == 2 && theme == 1){
                return `color:#3A5A40`
            }
            if(page == 2 && (theme == 2 || theme==3)){
                return `color:#D4ADFC`
                
            }
            if(page != 2 && theme == 0){
                return `color:#DAD7CD`
                
            }
            if(page != 2 && theme == 1){
                return `color:#588157`
            }
            if(page != 2 && (theme == 2 || theme==3)){
                return `color:#F8F7F6;`
            }
            else
            return `color:#A3B18A`
        },
        text4(){
            let page = this.$store.getters['getPage']
            let theme = this.$store.getters['getTheme']
            if(page == 3 && theme == 0){
                return `color:#A3B18A`
            }
            if(page == 3 && theme == 1){
                return `color:#3A5A40`
            }
            if(page == 3 && (theme == 2 || theme==3)){
                return `color:#D4ADFC`
                
            }
            if(page != 3 && theme == 0){
                return `color:#DAD7CD`
                
            }
            if(page != 3 && theme == 1){
                return `color:#588157`
            }
            if(page != 3 && (theme == 2 || theme==3)){
                return `color:#F8F7F6;`
            }
            else
            return `color:#A3B18A`
        },
        text5(){
            let page = this.$store.getters['getPage']
            let theme = this.$store.getters['getTheme']
            if(page == 4 && theme == 0){
                return `color:#A3B18A`
            }
            if(page == 4 && theme == 1){
                return `color:#3A5A40`
            }
            if(page == 4 && (theme == 2 || theme==3)){
                return `color:#D4ADFC`
                
            }
            if(page != 4 && theme == 0){
                return `color:#DAD7CD`
                
            }
            if(page != 4 && theme == 1){
                return `color:#588157`
            }
            if(page != 4 && (theme == 2 || theme==3)){
                return `color:#F8F7F6;`
            }
            else
            return `color:#A3B18A`
        },
        text6(){
            let page = this.$store.getters['getPage']
            let theme = this.$store.getters['getTheme']
            if(page == 5 && theme == 0){
                return `color:#A3B18A`
            }
            if(page == 5 && theme == 1){
                return `color:#3A5A40`
            }
            if(page == 5 && (theme == 2 || theme==3)){
                return `color:#D4ADFC`
                
            }
            if(page != 5 && theme == 0){
                return `color:#DAD7CD`
                
            }
            if(page != 5 && theme == 1){
                return `color:#588157`
            }
            if(page != 5 && (theme == 2 || theme==3)){
                return `color:#F8F7F6;`
            }
            else
            return `color:#A3B18A`
        },
    },
    methods:{
        changeTheme(){
            let val = this.$store.getters['getTheme'];
            if(val == 0) val=1;
            else if(val==1) val=0;
            this.$store.commit('setTheme',{value:val})
        }
    }
}
</script>

<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Ingrid+Darling&display=swap');

@media only screen and (max-width:480px){
    #header{
        display: none;
    }
}
@media only screen and (min-width:481px) and (max-width:960px){
    #header{
        width:100vw;
        height:7vh;
        position: fixed;
        top:0;
        left:0;
        display: grid;
        grid-template-columns: 10vw 80vw;
        z-index:1;
        overflow-x:hidden;
    }
#dumka{
    color: #FFF;
font-family: Ingrid Darling;
font-size: 38px;
font-style: normal;
font-weight: 400;
line-height: normal;
margin-top:5px;
margin-left:2.5vw;
cursor: pointer;
}
#nav{
    width:80vw;
    display: flex;
    flex-direction: row;
    padding-left:23vw;
}
.text-theme-1{
    color:#DAD7CD;
    cursor: pointer;
    font-family: 'Roboto';
    margin-right:1.5vw;
    margin-left:1.5vw;
    font-weight: 300;
}
.text-theme-2{
color:#588157;
cursor: pointer;
font-family: 'Roboto';
margin-right:1.5vw;
    margin-left:1.5vw;
    font-weight: 300;
}
.text-theme-1:hover{
   color: #A3B18A
}
.text-theme-2:hover{
    color:#3A5A40
}
#nav svg{
    margin-top:1.75vh;
    margin-left:2vw;
    cursor: pointer;
}
}
@media only screen and (min-width:961px){
    #header{
        width:100vw;
        height:7vh;
        position: fixed;
        top:0;
        left:0;
        display: grid;
        grid-template-columns: 30vw 60vw;
        z-index:1;
    }
#dumka{
    color: #FFF;
font-family: Ingrid Darling;
font-size: 32px;
font-style: normal;
font-weight: 400;
line-height: normal;
margin-top:5px;
margin-left:2.5vw;
cursor: pointer;
}
#nav{
    width:60vw;
    display: flex;
    flex-direction: row;
    padding-left:18vw;
}
.text-theme-1{
    color:#DAD7CD;
    cursor: pointer;
    font-family: 'Roboto';
    margin-right:1.5vw;
    margin-left:1.5vw;
    font-weight: 300;
}
.text-theme-2{
color:#588157;
cursor: pointer;
font-family: 'Roboto';
margin-right:1.5vw;
    margin-left:1.5vw;
    font-weight: 300;
}
.text-theme-1:hover{
   color: #A3B18A
}
.text-theme-2:hover{
    color:#3A5A40
}
#nav svg{
    margin-top:1.75vh;
    margin-left:2vw;
    cursor: pointer;
}
}

</style>