<template>
    <div id="fourth-mobile" :style="bg">
        <h2 :class="theme==0?'text-theme-1':theme==1?'text-theme-2':theme==2?'text-theme-3':'text-theme-4'">Work Experience</h2>
         <h3 :class="theme==1?'h3-theme-2':'h3-theme-1'">My professional journey</h3>
         <div id="qualificatons">
            <div id="qual-left">
                <div class="qualification" style="margin-top:2vh;">
                    <h3 :class="theme==1?'degree-2':'degree-1'">Fullstack Developer</h3>
                    <h4 :class="theme==1?'place-2':'place-1'">Kuvaka Tech</h4>
                    <h4 :class="theme==1?'duration-2':'duration-1'">08/2023-12/2023</h4>
                </div>
                <div class="qualification" style="margin-top:17vh;">
                    <h3 :class="theme==1?'degree-2':'degree-1'">Backend Developer</h3>
                    <h4 :class="theme==1?'place-2':'place-1'">Sellular</h4>
                    <h4 :class="theme==1?'duration-2':'duration-1'">08/2022-01/2023</h4>
                </div>
            </div>
            <div id="qual-divider">
                <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 15 15" fill="none" class="qual-circle" v-if="theme==0">
                    <circle cx="7.5" cy="7.5" r="7.5" fill="#A3B18A"/>
                  </svg>
                  <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 15 15" fill="none" class="qual-circle" v-if="theme==1">
                    <circle cx="7.5" cy="7.5" r="7.5" fill="#3A5A40"/>
                  </svg>
                  <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 15 15" fill="none" class="qual-circle" v-if="theme==2">
                    <circle cx="7.5" cy="7.5" r="7.5" fill="#5C469C"/>
                  </svg>
                  <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 15 15" fill="none" class="qual-circle" v-if="theme==3">
                    <circle cx="7.5" cy="7.5" r="7.5" fill="#D4ADFC"/>
                  </svg>
                <div :class="theme==0?'qual-line-1':theme==1?'qual-line-2':theme==2?'qual-line-3':'qual-line-4'"></div>
                <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 15 15" fill="none" class="qual-circle" v-if="theme==0">
                    <circle cx="7.5" cy="7.5" r="7.5" fill="#A3B18A"/>
                  </svg>
                  <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 15 15" fill="none" class="qual-circle" v-if="theme==1">
                    <circle cx="7.5" cy="7.5" r="7.5" fill="#3A5A40"/>
                  </svg>
                  <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 15 15" fill="none" class="qual-circle" v-if="theme==2">
                    <circle cx="7.5" cy="7.5" r="7.5" fill="#5C469C"/>
                  </svg>
                  <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 15 15" fill="none" class="qual-circle" v-if="theme==3">
                    <circle cx="7.5" cy="7.5" r="7.5" fill="#D4ADFC"/>
                  </svg>
                  <div :class="theme==0?'qual-line-1':theme==1?'qual-line-2':theme==2?'qual-line-3':'qual-line-4'"></div>
                  <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 15 15" fill="none" class="qual-circle" v-if="theme==0">
                    <circle cx="7.5" cy="7.5" r="7.5" fill="#A3B18A"/>
                  </svg>
                  <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 15 15" fill="none" class="qual-circle" v-if="theme==1">
                    <circle cx="7.5" cy="7.5" r="7.5" fill="#3A5A40"/>
                  </svg>
                  <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 15 15" fill="none" class="qual-circle" v-if="theme==2">
                    <circle cx="7.5" cy="7.5" r="7.5" fill="#5C469C"/>
                  </svg>
                  <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 15 15" fill="none" class="qual-circle" v-if="theme==3">
                    <circle cx="7.5" cy="7.5" r="7.5" fill="#D4ADFC"/>
                  </svg>
            </div>
            <div id="qual-right">
                <div class="qualification" style="margin-top:19vh;">
                    <h3 :class="theme==1?'degree-2':'degree-1'">Backend Intern</h3>
                    <h4 :class="theme==1?'place-2':'place-1'">Newton School</h4>
                    <h4 :class="theme==1?'duration-2':'duration-1'">01/2023-07-2023</h4>
                </div>
            </div>
         </div>
         <h2 :class="theme==0?'text-theme-1':theme==1?'text-theme-2':theme==2?'text-theme-3':'text-theme-4'">Qualificatons</h2>
         <h3 :class="theme==1?'h3-theme-2':'h3-theme-1'">My academic journey</h3>
         <div id="qualificatons">
            <div id="qual-left">
                <div class="qualification" style="margin-top:2vh;">
                    <h3 :class="theme==1?'degree-2':'degree-1'">B.E Electronics & Communication</h3>
                    <h4 :class="theme==1?'place-2':'place-1'">N.I.E Mysuru</h4>
                    <h4 :class="theme==1?'duration-2':'duration-1'">2019-2023</h4>
                </div>
                <div class="qualification" style="margin-top:17vh;">
                    <h3 :class="theme==1?'degree-2':'degree-1'">X 94.5%</h3>
                    <h4 :class="theme==1?'place-2':'place-1'">St Joseph’s 
                        Convent School</h4>
                    <h4 :class="theme==1?'duration-2':'duration-1'">2016</h4>
                </div>
            </div>
            <div id="qual-divider">
                <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 15 15" fill="none" class="qual-circle" v-if="theme==0">
                    <circle cx="7.5" cy="7.5" r="7.5" fill="#A3B18A"/>
                  </svg>
                  <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 15 15" fill="none" class="qual-circle" v-if="theme==1">
                    <circle cx="7.5" cy="7.5" r="7.5" fill="#3A5A40"/>
                  </svg>
                  <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 15 15" fill="none" class="qual-circle" v-if="theme==2">
                    <circle cx="7.5" cy="7.5" r="7.5" fill="#5C469C"/>
                  </svg>
                  <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 15 15" fill="none" class="qual-circle" v-if="theme==3">
                    <circle cx="7.5" cy="7.5" r="7.5" fill="#D4ADFC"/>
                  </svg>
                <div :class="theme==0?'qual-line-1':theme==1?'qual-line-2':theme==2?'qual-line-3':'qual-line-4'"></div>
                <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 15 15" fill="none" class="qual-circle" v-if="theme==0">
                    <circle cx="7.5" cy="7.5" r="7.5" fill="#A3B18A"/>
                  </svg>
                  <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 15 15" fill="none" class="qual-circle" v-if="theme==1">
                    <circle cx="7.5" cy="7.5" r="7.5" fill="#3A5A40"/>
                  </svg>
                  <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 15 15" fill="none" class="qual-circle" v-if="theme==2">
                    <circle cx="7.5" cy="7.5" r="7.5" fill="#5C469C"/>
                  </svg>
                  <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 15 15" fill="none" class="qual-circle" v-if="theme==3">
                    <circle cx="7.5" cy="7.5" r="7.5" fill="#D4ADFC"/>
                  </svg>
                  <div :class="theme==0?'qual-line-1':theme==1?'qual-line-2':theme==2?'qual-line-3':'qual-line-4'"></div>
                  <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 15 15" fill="none" class="qual-circle" v-if="theme==0">
                    <circle cx="7.5" cy="7.5" r="7.5" fill="#A3B18A"/>
                  </svg>
                  <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 15 15" fill="none" class="qual-circle" v-if="theme==1">
                    <circle cx="7.5" cy="7.5" r="7.5" fill="#3A5A40"/>
                  </svg>
                  <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 15 15" fill="none" class="qual-circle" v-if="theme==2">
                    <circle cx="7.5" cy="7.5" r="7.5" fill="#5C469C"/>
                  </svg>
                  <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 15 15" fill="none" class="qual-circle" v-if="theme==3">
                    <circle cx="7.5" cy="7.5" r="7.5" fill="#D4ADFC"/>
                  </svg>
            </div>
            <div id="qual-right">
                <div class="qualification" style="margin-top:19vh;">
                    <h3 :class="theme==1?'degree-2':'degree-1'">XII 90%</h3>
                    <h4 :class="theme==1?'place-2':'place-1'">St Joseph’s 
                        Convent School</h4>
                    <h4 :class="theme==1?'duration-2':'duration-1'">2018</h4>
                </div>
            </div>
         </div>
         <h2 :class="theme==0?'text-theme-1':theme==1?'text-theme-2':theme==2?'text-theme-3':'text-theme-4'">Services</h2>
         <h3 :class="theme==1?'h3-theme-2':'h3-theme-1'">What I offer</h3>
         <div id="services">
            <div class="service-row">
                <div :class="theme==0?'service-card-1':theme==1?'service-card-2':theme==2?'service-card-3':'service-card-4'">
                    <div class="service-icon">
                        <svg xmlns="http://www.w3.org/2000/svg" width="34" height="34" viewBox="0 0 34 34" fill="none" v-if="theme==0">
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M10.1628 6.84663C10.4209 7.14566 10.5659 7.55101 10.5659 7.97363C10.5659 8.39626 10.4209 8.8016 10.1628 9.10063L3.32551 17.0109L10.1628 24.9211C10.4062 25.2234 10.5388 25.6233 10.5325 26.0365C10.5262 26.4496 10.3815 26.8438 10.129 27.136C9.87642 27.4282 9.53568 27.5956 9.17856 27.6029C8.82145 27.6102 8.47582 27.4568 8.21451 27.1751L0.40314 18.1379C0.144997 17.8388 0 17.4335 0 17.0109C0 16.5882 0.144997 16.1829 0.40314 15.8839L8.21451 6.84663C8.47297 6.54798 8.82333 6.38023 9.18863 6.38023C9.55393 6.38023 9.90429 6.54798 10.1628 6.84663ZM23.8372 6.84663C24.0957 6.54798 24.4461 6.38023 24.8114 6.38023C25.1767 6.38023 25.527 6.54798 25.7855 6.84663L33.5969 15.8839C33.855 16.1829 34 16.5882 34 17.0109C34 17.4335 33.855 17.8388 33.5969 18.1379L25.7855 27.1751C25.5242 27.4568 25.1786 27.6102 24.8214 27.6029C24.4643 27.5956 24.1236 27.4282 23.871 27.136C23.6185 26.8438 23.4738 26.4496 23.4675 26.0365C23.4612 25.6233 23.5938 25.2234 23.8372 24.9211L30.6745 17.0109L23.8372 9.10063C23.5791 8.8016 23.4341 8.39626 23.4341 7.97363C23.4341 7.55101 23.5791 7.14566 23.8372 6.84663ZM19.5309 0.0229879C19.7094 0.0584838 19.8802 0.134344 20.0333 0.24623C20.1865 0.358117 20.3191 0.503837 20.4236 0.675057C20.5281 0.846277 20.6023 1.03964 20.6422 1.24409C20.682 1.44854 20.6866 1.66007 20.6557 1.86659L16.0608 32.6995C15.994 33.1112 15.7898 33.476 15.4925 33.715C15.1951 33.9539 14.8284 34.048 14.4716 33.9768C14.1148 33.9056 13.7966 33.6748 13.5856 33.3343C13.3747 32.9939 13.288 32.5711 13.3443 32.1573L17.9392 1.32435C18.0012 0.907629 18.2037 0.536445 18.5022 0.292408C18.8006 0.0483722 19.1707 -0.0485374 19.5309 0.0229879Z" fill="#3A5A40"/>
                          </svg>
                        <svg xmlns="http://www.w3.org/2000/svg" width="34" height="34" viewBox="0 0 34 34" fill="none" v-if="theme==1">
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M10.1628 6.84663C10.4209 7.14566 10.5659 7.55101 10.5659 7.97363C10.5659 8.39626 10.4209 8.8016 10.1628 9.10063L3.32551 17.0109L10.1628 24.9211C10.4062 25.2234 10.5388 25.6233 10.5325 26.0365C10.5262 26.4496 10.3815 26.8438 10.129 27.136C9.87642 27.4282 9.53568 27.5956 9.17856 27.6029C8.82145 27.6102 8.47582 27.4568 8.21451 27.1751L0.40314 18.1379C0.144997 17.8388 0 17.4335 0 17.0109C0 16.5882 0.144997 16.1829 0.40314 15.8839L8.21451 6.84663C8.47297 6.54798 8.82333 6.38023 9.18863 6.38023C9.55393 6.38023 9.90429 6.54798 10.1628 6.84663ZM23.8372 6.84663C24.0957 6.54798 24.4461 6.38023 24.8114 6.38023C25.1767 6.38023 25.527 6.54798 25.7855 6.84663L33.5969 15.8839C33.855 16.1829 34 16.5882 34 17.0109C34 17.4335 33.855 17.8388 33.5969 18.1379L25.7855 27.1751C25.5242 27.4568 25.1786 27.6102 24.8214 27.6029C24.4643 27.5956 24.1236 27.4282 23.871 27.136C23.6185 26.8438 23.4738 26.4496 23.4675 26.0365C23.4612 25.6233 23.5938 25.2234 23.8372 24.9211L30.6745 17.0109L23.8372 9.10063C23.5791 8.8016 23.4341 8.39626 23.4341 7.97363C23.4341 7.55101 23.5791 7.14566 23.8372 6.84663ZM19.5309 0.0229879C19.7094 0.0584838 19.8802 0.134344 20.0333 0.24623C20.1865 0.358117 20.3191 0.503837 20.4236 0.675057C20.5281 0.846277 20.6023 1.03964 20.6422 1.24409C20.682 1.44854 20.6866 1.66007 20.6557 1.86659L16.0608 32.6995C15.994 33.1112 15.7898 33.476 15.4925 33.715C15.1951 33.9539 14.8284 34.048 14.4716 33.9768C14.1148 33.9056 13.7966 33.6748 13.5856 33.3343C13.3747 32.9939 13.288 32.5711 13.3443 32.1573L17.9392 1.32435C18.0012 0.907629 18.2037 0.536445 18.5022 0.292408C18.8006 0.0483722 19.1707 -0.0485374 19.5309 0.0229879Z" fill="#A3B18A"/>
                          </svg>
                          <svg xmlns="http://www.w3.org/2000/svg" width="34" height="34" viewBox="0 0 34 34" fill="none" v-if="theme==2 || theme==3">
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M10.1628 6.84663C10.4209 7.14566 10.5659 7.55101 10.5659 7.97363C10.5659 8.39626 10.4209 8.8016 10.1628 9.10063L3.32551 17.0109L10.1628 24.9211C10.4062 25.2234 10.5388 25.6233 10.5325 26.0365C10.5262 26.4496 10.3815 26.8438 10.129 27.136C9.87642 27.4282 9.53568 27.5956 9.17856 27.6029C8.82145 27.6102 8.47582 27.4568 8.21451 27.1751L0.40314 18.1379C0.144997 17.8388 0 17.4335 0 17.0109C0 16.5882 0.144997 16.1829 0.40314 15.8839L8.21451 6.84663C8.47297 6.54798 8.82333 6.38023 9.18863 6.38023C9.55393 6.38023 9.90429 6.54798 10.1628 6.84663ZM23.8372 6.84663C24.0957 6.54798 24.4461 6.38023 24.8114 6.38023C25.1767 6.38023 25.527 6.54798 25.7855 6.84663L33.5969 15.8839C33.855 16.1829 34 16.5882 34 17.0109C34 17.4335 33.855 17.8388 33.5969 18.1379L25.7855 27.1751C25.5242 27.4568 25.1786 27.6102 24.8214 27.6029C24.4643 27.5956 24.1236 27.4282 23.871 27.136C23.6185 26.8438 23.4738 26.4496 23.4675 26.0365C23.4612 25.6233 23.5938 25.2234 23.8372 24.9211L30.6745 17.0109L23.8372 9.10063C23.5791 8.8016 23.4341 8.39626 23.4341 7.97363C23.4341 7.55101 23.5791 7.14566 23.8372 6.84663ZM19.5309 0.0229879C19.7094 0.0584838 19.8802 0.134344 20.0333 0.24623C20.1865 0.358117 20.3191 0.503837 20.4236 0.675057C20.5281 0.846277 20.6023 1.03964 20.6422 1.24409C20.682 1.44854 20.6866 1.66007 20.6557 1.86658L16.0608 32.6995C15.994 33.1112 15.7898 33.476 15.4925 33.715C15.1951 33.9539 14.8284 34.048 14.4716 33.9768C14.1148 33.9056 13.7966 33.6748 13.5856 33.3343C13.3747 32.9939 13.288 32.5711 13.3443 32.1573L17.9392 1.32435C18.0012 0.907629 18.2037 0.536445 18.5022 0.292408C18.8006 0.0483722 19.1707 -0.0485374 19.5309 0.0229879Z" fill="#1D267D"/>
                          </svg>
                          
                    </div>
                    <h2 :class="theme==0?'service-h2-1':theme==1?'service-h2-2':'service-h2-3'">Frontend Development</h2>
                    <ul :class="theme==0?'service-list-1':theme==1?'service-list-2':'service-list-3'">
                        <li :class="theme==0?'service-li-1':theme==1?'service-li-2':'service-li-3'">Web Design</li>
                        <li :class="theme==0?'service-li-1':theme==1?'service-li-2':'service-li-3'">Responsive Design</li>
                        <li :class="theme==0?'service-li-1':theme==1?'service-li-2':'service-li-3'">PWA</li>
                    </ul>
                </div>
                <div :class="theme==0?'service-card-1':theme==1?'service-card-2':theme==2?'service-card-3':'service-card-4'">
                    <div class="service-icon">
                        <svg xmlns="http://www.w3.org/2000/svg" width="33" height="32" viewBox="0 0 33 32" fill="none" v-if="theme==0">
                            <path d="M33 6.8569C33 7.46309 32.7517 8.04445 32.3096 8.47309C31.8676 8.90172 31.268 9.14253 30.6429 9.14253H25.9286V27.5704H25.9262C25.9067 28.1796 25.7618 28.7788 25.5 29.3329C25.2382 29.8869 24.8648 30.3846 24.4017 30.7967C23.9387 31.2088 23.3953 31.5269 22.8036 31.7324C22.2119 31.9378 21.5837 32.0265 20.9562 31.9932V31.9989H4.71429C3.46398 31.9989 2.26488 31.5172 1.38078 30.66C0.496682 29.8027 0 28.64 0 27.4276V25.142C0 24.5358 0.248341 23.9544 0.690391 23.5258C1.13244 23.0971 1.73199 22.8563 2.35714 22.8563H4.71429V5.71408C4.71429 4.19861 5.33514 2.74521 6.44026 1.67362C7.54539 0.602018 9.04426 0 10.6071 0H28.2857C29.536 0 30.7351 0.481614 31.6192 1.33889C32.5033 2.19617 33 3.35889 33 4.57127V6.8569ZM23.5714 27.4276V4.57127C23.5714 3.73815 23.8013 2.95761 24.202 2.28563H10.6071C9.66941 2.28563 8.77009 2.64684 8.10701 3.2898C7.44394 3.93276 7.07143 4.8048 7.07143 5.71408V22.8563H16.5C17.1252 22.8563 17.7247 23.0971 18.1668 23.5258C18.6088 23.9544 18.8571 24.5358 18.8571 25.142V27.4276C18.8571 28.0338 19.1055 28.6151 19.5475 29.0438C19.9896 29.4724 20.5891 29.7132 21.2143 29.7132C21.8394 29.7132 22.439 29.4724 22.881 29.0438C23.3231 28.6151 23.5714 28.0338 23.5714 27.4276ZM25.9286 4.57127V6.8569H30.6429V4.57127C30.6429 3.96508 30.3945 3.38372 29.9525 2.95508C29.5104 2.52644 28.9109 2.28563 28.2857 2.28563H28.2834C27.6586 2.28624 27.0597 2.52731 26.6181 2.95589C26.1766 3.38446 25.9286 3.96547 25.9286 4.57127ZM2.35714 25.142V27.4276C2.35714 28.0338 2.60548 28.6151 3.04753 29.0438C3.48958 29.4724 4.08913 29.7132 4.71429 29.7132H17.1305C16.7162 29.0186 16.4987 28.2301 16.5 27.4276V25.142H2.35714Z" fill="#3A5A40"/>
                          </svg>
                          <svg xmlns="http://www.w3.org/2000/svg" width="33" height="32" viewBox="0 0 33 32" fill="none" v-if="theme==1">
                            <path d="M33 6.8569C33 7.46309 32.7517 8.04445 32.3096 8.47309C31.8676 8.90172 31.268 9.14253 30.6429 9.14253H25.9286V27.5704H25.9262C25.9067 28.1796 25.7618 28.7788 25.5 29.3329C25.2382 29.8869 24.8648 30.3846 24.4017 30.7967C23.9387 31.2088 23.3953 31.5269 22.8036 31.7324C22.2119 31.9378 21.5837 32.0265 20.9562 31.9932V31.9989H4.71429C3.46398 31.9989 2.26488 31.5172 1.38078 30.66C0.496682 29.8027 0 28.64 0 27.4276V25.142C0 24.5358 0.248341 23.9544 0.690391 23.5258C1.13244 23.0971 1.73199 22.8563 2.35714 22.8563H4.71429V5.71408C4.71429 4.19861 5.33514 2.74521 6.44026 1.67362C7.54539 0.602018 9.04426 0 10.6071 0H28.2857C29.536 0 30.7351 0.481614 31.6192 1.33889C32.5033 2.19617 33 3.35889 33 4.57127V6.8569ZM23.5714 27.4276V4.57127C23.5714 3.73815 23.8013 2.95761 24.202 2.28563H10.6071C9.66941 2.28563 8.77009 2.64684 8.10701 3.2898C7.44394 3.93276 7.07143 4.8048 7.07143 5.71408V22.8563H16.5C17.1252 22.8563 17.7247 23.0971 18.1668 23.5258C18.6088 23.9544 18.8571 24.5358 18.8571 25.142V27.4276C18.8571 28.0338 19.1055 28.6151 19.5475 29.0438C19.9896 29.4724 20.5891 29.7132 21.2143 29.7132C21.8394 29.7132 22.439 29.4724 22.881 29.0438C23.3231 28.6151 23.5714 28.0338 23.5714 27.4276ZM25.9286 4.57127V6.8569H30.6429V4.57127C30.6429 3.96508 30.3945 3.38372 29.9525 2.95508C29.5104 2.52644 28.9109 2.28563 28.2857 2.28563H28.2834C27.6586 2.28624 27.0597 2.52731 26.6181 2.95589C26.1766 3.38446 25.9286 3.96547 25.9286 4.57127ZM2.35714 25.142V27.4276C2.35714 28.0338 2.60548 28.6151 3.04753 29.0438C3.48958 29.4724 4.08913 29.7132 4.71429 29.7132H17.1305C16.7162 29.0186 16.4987 28.2301 16.5 27.4276V25.142H2.35714Z" fill="#A3B18A"/>
                          </svg>
                          <svg xmlns="http://www.w3.org/2000/svg" width="33" height="32" viewBox="0 0 33 32" fill="none" v-if="theme==2 || theme==3">
                            <path d="M33 6.8569C33 7.46309 32.7517 8.04445 32.3096 8.47309C31.8676 8.90172 31.268 9.14253 30.6429 9.14253H25.9286V27.5704H25.9262C25.9067 28.1796 25.7618 28.7788 25.5 29.3329C25.2382 29.8869 24.8648 30.3846 24.4017 30.7967C23.9387 31.2088 23.3953 31.5269 22.8036 31.7324C22.2119 31.9378 21.5837 32.0265 20.9562 31.9932V31.9989H4.71429C3.46398 31.9989 2.26488 31.5172 1.38078 30.66C0.496682 29.8027 0 28.64 0 27.4276V25.142C0 24.5358 0.248341 23.9544 0.690391 23.5258C1.13244 23.0971 1.73199 22.8563 2.35714 22.8563H4.71429V5.71408C4.71429 4.19861 5.33514 2.74521 6.44026 1.67362C7.54539 0.602018 9.04426 0 10.6071 0H28.2857C29.536 0 30.7351 0.481614 31.6192 1.33889C32.5033 2.19617 33 3.35889 33 4.57127V6.8569ZM23.5714 27.4276V4.57127C23.5714 3.73815 23.8013 2.95761 24.202 2.28563H10.6071C9.66941 2.28563 8.77009 2.64684 8.10702 3.2898C7.44394 3.93276 7.07143 4.8048 7.07143 5.71408V22.8563H16.5C17.1252 22.8563 17.7247 23.0971 18.1668 23.5258C18.6088 23.9544 18.8571 24.5358 18.8571 25.142V27.4276C18.8571 28.0338 19.1055 28.6151 19.5475 29.0438C19.9896 29.4724 20.5891 29.7132 21.2143 29.7132C21.8394 29.7132 22.439 29.4724 22.881 29.0438C23.3231 28.6151 23.5714 28.0338 23.5714 27.4276ZM25.9286 4.57127V6.8569H30.6429V4.57127C30.6429 3.96508 30.3945 3.38372 29.9525 2.95508C29.5104 2.52644 28.9109 2.28563 28.2857 2.28563H28.2834C27.6586 2.28624 27.0597 2.52731 26.6181 2.95589C26.1766 3.38446 25.9286 3.96547 25.9286 4.57127ZM2.35714 25.142V27.4276C2.35714 28.0338 2.60548 28.6151 3.04753 29.0438C3.48958 29.4724 4.08913 29.7132 4.71429 29.7132H17.1305C16.7162 29.0186 16.4987 28.2301 16.5 27.4276V25.142H2.35714Z" fill="#1D267D"/>
                          </svg>
                          
                          
                    </div>
                    <h2 :class="theme==0?'service-h2-1':theme==1?'service-h2-2':'service-h2-3'">Scripting</h2>
                    <ul :class="theme==0?'service-list-1':theme==1?'service-list-2':'service-list-3'">
                        <li :class="theme==0?'service-li-1':theme==1?'service-li-2':'service-li-3'">Bash/Automation</li>
                        <li :class="theme==0?'service-li-1':theme==1?'service-li-2':'service-li-3'">Python/Web scrapping and automation</li>
                    </ul>
                </div>
            </div>
            <div class="service-row">
                <div :class="theme==0?'service-card-1':theme==1?'service-card-2':theme==2?'service-card-3':'service-card-4'">
                    <div class="service-icon">
                        <svg xmlns="http://www.w3.org/2000/svg" width="39" height="45" viewBox="0 0 39 45" fill="none" v-if="theme==0">
                            <path d="M9.75 14.0625C9.75 13.5652 9.92121 13.0883 10.226 12.7367C10.5307 12.385 10.944 12.1875 11.375 12.1875C11.806 12.1875 12.2193 12.385 12.524 12.7367C12.8288 13.0883 13 13.5652 13 14.0625C13 14.5598 12.8288 15.0367 12.524 15.3883C12.2193 15.74 11.806 15.9375 11.375 15.9375C10.944 15.9375 10.5307 15.74 10.226 15.3883C9.92121 15.0367 9.75 14.5598 9.75 14.0625ZM14.625 14.0625C14.625 13.5652 14.7962 13.0883 15.101 12.7367C15.4057 12.385 15.819 12.1875 16.25 12.1875C16.681 12.1875 17.0943 12.385 17.399 12.7367C17.7038 13.0883 17.875 13.5652 17.875 14.0625C17.875 14.5598 17.7038 15.0367 17.399 15.3883C17.0943 15.74 16.681 15.9375 16.25 15.9375C15.819 15.9375 15.4057 15.74 15.101 15.3883C14.7962 15.0367 14.625 14.5598 14.625 14.0625ZM11.375 25.3125C10.944 25.3125 10.5307 25.51 10.226 25.8617C9.92121 26.2133 9.75 26.6902 9.75 27.1875C9.75 27.6848 9.92121 28.1617 10.226 28.5133C10.5307 28.865 10.944 29.0625 11.375 29.0625C11.806 29.0625 12.2193 28.865 12.524 28.5133C12.8288 28.1617 13 27.6848 13 27.1875C13 26.6902 12.8288 26.2133 12.524 25.8617C12.2193 25.51 11.806 25.3125 11.375 25.3125ZM16.25 25.3125C15.819 25.3125 15.4057 25.51 15.101 25.8617C14.7962 26.2133 14.625 26.6902 14.625 27.1875C14.625 27.6848 14.7962 28.1617 15.101 28.5133C15.4057 28.865 15.819 29.0625 16.25 29.0625C16.681 29.0625 17.0943 28.865 17.399 28.5133C17.7038 28.1617 17.875 27.6848 17.875 27.1875C17.875 26.6902 17.7038 26.2133 17.399 25.8617C17.0943 25.51 16.681 25.3125 16.25 25.3125Z" fill="#3A5A40"/>
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M9.66391 6.09375C8.47873 6.09375 7.34208 6.637 6.50403 7.60398C5.66598 8.57096 5.19516 9.88248 5.19516 11.25V16.875C5.19516 18.3525 5.73466 19.6856 6.59754 20.625C6.15416 21.1064 5.80098 21.6875 5.55978 22.3324C5.31858 22.9774 5.19449 23.6726 5.19516 24.375V30C5.19516 31.3675 5.66598 32.679 6.50403 33.646C7.34208 34.613 8.47873 35.1562 9.66391 35.1562H18.2813V37.9688H8.12504C7.80181 37.9688 7.49181 38.1169 7.26325 38.3806C7.03469 38.6444 6.90629 39.002 6.90629 39.375C6.90629 39.748 7.03469 40.1056 7.26325 40.3694C7.49181 40.6331 7.80181 40.7812 8.12504 40.7812H30.875C31.1983 40.7812 31.5083 40.6331 31.7368 40.3694C31.9654 40.1056 32.0938 39.748 32.0938 39.375C32.0938 39.002 31.9654 38.6444 31.7368 38.3806C31.5083 38.1169 31.1983 37.9688 30.875 37.9688H20.7188V35.1562H29.1639C29.7508 35.1562 30.3319 35.0229 30.874 34.7638C31.4162 34.5046 31.9088 34.1248 32.3238 33.646C32.7388 33.1672 33.0679 32.5988 33.2925 31.9732C33.5171 31.3476 33.6327 30.6771 33.6327 30V24.375C33.6327 22.8975 33.0948 21.5644 32.2319 20.625C32.6749 20.1434 33.0278 19.5622 33.2687 18.9173C33.5096 18.2723 33.6334 17.5773 33.6327 16.875V11.25C33.6327 10.5729 33.5171 9.90237 33.2925 9.27679C33.0679 8.6512 32.7388 8.08278 32.3238 7.60398C31.9088 7.12518 31.4162 6.74537 30.874 6.48625C30.3319 6.22712 29.7508 6.09375 29.1639 6.09375H9.66391ZM9.66391 19.2188H29.1639C30.2852 19.2188 31.1952 18.1687 31.1952 16.875V11.25C31.1952 9.95625 30.2852 8.90625 29.1639 8.90625H9.66391C8.54266 8.90625 7.63266 9.95625 7.63266 11.25V16.875C7.63266 18.1687 8.54266 19.2188 9.66391 19.2188ZM9.66391 22.0312C8.54266 22.0312 7.63266 23.0812 7.63266 24.375V30C7.63266 31.2938 8.54266 32.3438 9.66391 32.3438H29.1639C30.2852 32.3438 31.1952 31.2938 31.1952 30V24.375C31.1952 23.0812 30.2852 22.0312 29.1639 22.0312H9.66391Z" fill="#3A5A40"/>
                          </svg>
                          <svg xmlns="http://www.w3.org/2000/svg" width="37" height="41" viewBox="0 0 37 41" fill="none" v-if="theme==1">
                            <path d="M9.25 12.8125C9.25 12.3594 9.41243 11.9249 9.70154 11.6045C9.99066 11.2842 10.3828 11.1042 10.7917 11.1042C11.2005 11.1042 11.5927 11.2842 11.8818 11.6045C12.1709 11.9249 12.3333 12.3594 12.3333 12.8125C12.3333 13.2656 12.1709 13.7001 11.8818 14.0205C11.5927 14.3409 11.2005 14.5208 10.7917 14.5208C10.3828 14.5208 9.99066 14.3409 9.70154 14.0205C9.41243 13.7001 9.25 13.2656 9.25 12.8125ZM13.875 12.8125C13.875 12.3594 14.0374 11.9249 14.3265 11.6045C14.6157 11.2842 15.0078 11.1042 15.4167 11.1042C15.8255 11.1042 16.2177 11.2842 16.5068 11.6045C16.7959 11.9249 16.9583 12.3594 16.9583 12.8125C16.9583 13.2656 16.7959 13.7001 16.5068 14.0205C16.2177 14.3409 15.8255 14.5208 15.4167 14.5208C15.0078 14.5208 14.6157 14.3409 14.3265 14.0205C14.0374 13.7001 13.875 13.2656 13.875 12.8125ZM10.7917 23.0625C10.3828 23.0625 9.99066 23.2425 9.70154 23.5629C9.41243 23.8832 9.25 24.3178 9.25 24.7708C9.25 25.2239 9.41243 25.6584 9.70154 25.9788C9.99066 26.2992 10.3828 26.4792 10.7917 26.4792C11.2005 26.4792 11.5927 26.2992 11.8818 25.9788C12.1709 25.6584 12.3333 25.2239 12.3333 24.7708C12.3333 24.3178 12.1709 23.8832 11.8818 23.5629C11.5927 23.2425 11.2005 23.0625 10.7917 23.0625ZM15.4167 23.0625C15.0078 23.0625 14.6157 23.2425 14.3265 23.5629C14.0374 23.8832 13.875 24.3178 13.875 24.7708C13.875 25.2239 14.0374 25.6584 14.3265 25.9788C14.6157 26.2992 15.0078 26.4792 15.4167 26.4792C15.8255 26.4792 16.2177 26.2992 16.5068 25.9788C16.7959 25.6584 16.9583 25.2239 16.9583 24.7708C16.9583 24.3178 16.7959 23.8832 16.5068 23.5629C16.2177 23.2425 15.8255 23.0625 15.4167 23.0625Z" fill="#A3B18A"/>
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M9.16833 5.55208C8.04392 5.55208 6.96556 6.04704 6.17049 6.92807C5.37541 7.8091 4.92874 9.00403 4.92874 10.25V15.375C4.92874 16.7212 5.44058 17.9358 6.2592 18.7917C5.83856 19.2302 5.50349 19.7597 5.27466 20.3473C5.04584 20.935 4.92811 21.5683 4.92874 22.2083V27.3333C4.92874 28.5793 5.37541 29.7742 6.17049 30.6553C6.96556 31.5363 8.04392 32.0312 9.16833 32.0312H17.3438V34.5937H7.70837C7.40171 34.5937 7.10762 34.7287 6.89078 34.969C6.67394 35.2093 6.55212 35.5352 6.55212 35.875C6.55212 36.2148 6.67394 36.5407 6.89078 36.781C7.10762 37.0213 7.40171 37.1562 7.70837 37.1562H29.2917C29.5984 37.1562 29.8925 37.0213 30.1093 36.781C30.3261 36.5407 30.448 36.2148 30.448 35.875C30.448 35.5352 30.3261 35.2093 30.1093 34.969C29.8925 34.7287 29.5984 34.5937 29.2917 34.5937H19.6563V32.0312H27.6683C28.2251 32.0312 28.7764 31.9097 29.2907 31.6736C29.8051 31.4375 30.2725 31.0915 30.6662 30.6553C31.0598 30.219 31.3721 29.7011 31.5852 29.1311C31.7983 28.5612 31.9079 27.9503 31.9079 27.3333V22.2083C31.9079 20.8622 31.3976 19.6475 30.579 18.7917C30.9993 18.3529 31.334 17.8234 31.5626 17.2357C31.7911 16.6481 31.9087 16.0149 31.9079 15.375V10.25C31.9079 9.63306 31.7983 9.02216 31.5852 8.45218C31.3721 7.8822 31.0598 7.36431 30.6662 6.92807C30.2725 6.49182 29.8051 6.14578 29.2907 5.90969C28.7764 5.67359 28.2251 5.55208 27.6683 5.55208H9.16833ZM9.16833 17.5104H27.6683C28.7321 17.5104 29.5954 16.5537 29.5954 15.375V10.25C29.5954 9.07124 28.7321 8.11458 27.6683 8.11458H9.16833C8.10458 8.11458 7.24124 9.07124 7.24124 10.25V15.375C7.24124 16.5537 8.10458 17.5104 9.16833 17.5104ZM9.16833 20.0729C8.10458 20.0729 7.24124 21.0296 7.24124 22.2083V27.3333C7.24124 28.5121 8.10458 29.4687 9.16833 29.4687H27.6683C28.7321 29.4687 29.5954 28.5121 29.5954 27.3333V22.2083C29.5954 21.0296 28.7321 20.0729 27.6683 20.0729H9.16833Z" fill="#A3B18A"/>
                          </svg>
                          <svg xmlns="http://www.w3.org/2000/svg" width="37" height="41" viewBox="0 0 37 41" fill="none" v-if="theme==2 || theme==3">
                            <path d="M9.25 12.8126C9.25 12.3595 9.41243 11.925 9.70154 11.6046C9.99066 11.2842 10.3828 11.1042 10.7917 11.1042C11.2005 11.1042 11.5927 11.2842 11.8818 11.6046C12.1709 11.925 12.3333 12.3595 12.3333 12.8126C12.3333 13.2657 12.1709 13.7002 11.8818 14.0206C11.5927 14.3409 11.2005 14.5209 10.7917 14.5209C10.3828 14.5209 9.99066 14.3409 9.70154 14.0206C9.41243 13.7002 9.25 13.2657 9.25 12.8126ZM13.875 12.8126C13.875 12.3595 14.0374 11.925 14.3265 11.6046C14.6157 11.2842 15.0078 11.1042 15.4167 11.1042C15.8255 11.1042 16.2177 11.2842 16.5068 11.6046C16.7959 11.925 16.9583 12.3595 16.9583 12.8126C16.9583 13.2657 16.7959 13.7002 16.5068 14.0206C16.2177 14.3409 15.8255 14.5209 15.4167 14.5209C15.0078 14.5209 14.6157 14.3409 14.3265 14.0206C14.0374 13.7002 13.875 13.2657 13.875 12.8126ZM10.7917 23.0626C10.3828 23.0626 9.99066 23.2426 9.70154 23.5629C9.41243 23.8833 9.25 24.3178 9.25 24.7709C9.25 25.224 9.41243 25.6585 9.70154 25.9789C9.99066 26.2993 10.3828 26.4792 10.7917 26.4792C11.2005 26.4792 11.5927 26.2993 11.8818 25.9789C12.1709 25.6585 12.3333 25.224 12.3333 24.7709C12.3333 24.3178 12.1709 23.8833 11.8818 23.5629C11.5927 23.2426 11.2005 23.0626 10.7917 23.0626ZM15.4167 23.0626C15.0078 23.0626 14.6157 23.2426 14.3265 23.5629C14.0374 23.8833 13.875 24.3178 13.875 24.7709C13.875 25.224 14.0374 25.6585 14.3265 25.9789C14.6157 26.2993 15.0078 26.4792 15.4167 26.4792C15.8255 26.4792 16.2177 26.2993 16.5068 25.9789C16.7959 25.6585 16.9583 25.224 16.9583 24.7709C16.9583 24.3178 16.7959 23.8833 16.5068 23.5629C16.2177 23.2426 15.8255 23.0626 15.4167 23.0626Z" fill="#1D267D"/>
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M9.1683 5.552C8.04389 5.552 6.96553 6.04696 6.17046 6.92799C5.37538 7.80902 4.92871 9.00395 4.92871 10.2499V15.3749C4.92871 16.7211 5.44055 17.9357 6.25917 18.7916C5.83853 19.2302 5.50346 19.7596 5.27463 20.3472C5.04581 20.9349 4.92808 21.5682 4.92871 22.2083V27.3333C4.92871 28.5792 5.37538 29.7742 6.17046 30.6552C6.96553 31.5362 8.04389 32.0312 9.1683 32.0312H17.3438V34.5937H7.70834C7.40168 34.5937 7.10758 34.7287 6.89075 34.9689C6.67391 35.2092 6.55209 35.5351 6.55209 35.8749C6.55209 36.2147 6.67391 36.5406 6.89075 36.7809C7.10758 37.0212 7.40168 37.1562 7.70834 37.1562H29.2917C29.5983 37.1562 29.8924 37.0212 30.1093 36.7809C30.3261 36.5406 30.4479 36.2147 30.4479 35.8749C30.4479 35.5351 30.3261 35.2092 30.1093 34.9689C29.8924 34.7287 29.5983 34.5937 29.2917 34.5937H19.6563V32.0312H27.6683C28.225 32.0312 28.7763 31.9097 29.2907 31.6736C29.8051 31.4375 30.2725 31.0914 30.6661 30.6552C31.0598 30.2189 31.3721 29.701 31.5852 29.1311C31.7982 28.5611 31.9079 27.9502 31.9079 27.3333V22.2083C31.9079 20.8621 31.3976 19.6475 30.579 18.7916C30.9992 18.3528 31.334 17.8233 31.5625 17.2357C31.7911 16.648 31.9086 16.0148 31.9079 15.3749V10.2499C31.9079 9.63298 31.7982 9.02208 31.5852 8.4521C31.3721 7.88213 31.0598 7.36423 30.6661 6.92799C30.2725 6.49175 29.8051 6.1457 29.2907 5.90961C28.7763 5.67352 28.225 5.552 27.6683 5.552H9.1683ZM9.1683 17.5103H27.6683C28.732 17.5103 29.5954 16.5537 29.5954 15.3749V10.2499C29.5954 9.07117 28.732 8.1145 27.6683 8.1145H9.1683C8.10455 8.1145 7.24121 9.07117 7.24121 10.2499V15.3749C7.24121 16.5537 8.10455 17.5103 9.1683 17.5103ZM9.1683 20.0728C8.10455 20.0728 7.24121 21.0295 7.24121 22.2083V27.3333C7.24121 28.512 8.10455 29.4687 9.1683 29.4687H27.6683C28.732 29.4687 29.5954 28.512 29.5954 27.3333V22.2083C29.5954 21.0295 28.732 20.0728 27.6683 20.0728H9.1683Z" fill="#1D267D"/>
                          </svg>
                    </div>
                    <h2 :class="theme==0?'service-h2-1':theme==1?'service-h2-2':'service-h2-3'">Backend Development</h2>
                    <ul :class="theme==0?'service-list-1':theme==1?'service-list-2':'service-list-3'">
                        <li :class="theme==0?'service-li-1':theme==1?'service-li-2':'service-li-3'">PostgreSQL</li>
                        <li :class="theme==0?'service-li-1':theme==1?'service-li-2':'service-li-3'">MongoDb</li>
                        <li :class="theme==0?'service-li-1':theme==1?'service-li-2':'service-li-3'">AWS</li>
                    </ul>
                </div>
                <div :class="theme==0?'service-card-1':theme==1?'service-card-2':theme==2?'service-card-3':'service-card-4'">
                    <div class="service-icon">
                        <svg xmlns="http://www.w3.org/2000/svg" width="34" height="44" viewBox="0 0 34 44" fill="none" v-if="theme==0">
                            <path d="M25 29C29.4183 29 33 25.866 33 22C33 18.134 29.4183 15 25 15C20.5817 15 17 18.134 17 22C17 25.866 20.5817 29 25 29Z" stroke="#344E41" stroke-linecap="round" stroke-linejoin="round"/>
                            <path d="M9 29C7.41775 29 5.87103 29.4105 4.55544 30.1797C3.23985 30.9489 2.21447 32.0421 1.60897 33.3212C1.00347 34.6003 0.84504 36.0078 1.15372 37.3656C1.4624 38.7235 2.22433 39.9708 3.34315 40.9497C4.46197 41.9287 5.88743 42.5954 7.43928 42.8655C8.99113 43.1356 10.5997 42.997 12.0615 42.4672C13.5233 41.9373 14.7727 41.0401 15.6518 39.889C16.5308 38.7378 17 37.3845 17 36V29M9 29H17M9 29C6.87827 29 4.84344 28.2625 3.34315 26.9497C1.84286 25.637 1 23.8565 1 22C1 20.1435 1.84286 18.363 3.34315 17.0503C4.84344 15.7375 6.87827 15 9 15M17 29V15M17 1H25C27.1217 1 29.1566 1.7375 30.6569 3.05025C32.1571 4.36301 33 6.14348 33 8C33 9.85651 32.1571 11.637 30.6569 12.9497C29.1566 14.2625 27.1217 15 25 15H17M17 1V15M17 1H9C6.87827 1 4.84344 1.7375 3.34315 3.05025C1.84286 4.36301 1 6.14348 1 8C1 9.85651 1.84286 11.637 3.34315 12.9497C4.84344 14.2625 6.87827 15 9 15M17 15H9" stroke="#344E41" stroke-linecap="round" stroke-linejoin="round"/>
                          </svg>
                          <svg xmlns="http://www.w3.org/2000/svg" width="34" height="44" viewBox="0 0 34 44" fill="none" v-if="theme==1">
                            <path d="M25 29C29.4183 29 33 25.866 33 22C33 18.134 29.4183 15 25 15C20.5817 15 17 18.134 17 22C17 25.866 20.5817 29 25 29Z" stroke="#A3B18A" stroke-linecap="round" stroke-linejoin="round"/>
                            <path d="M9 29C7.41775 29 5.87103 29.4105 4.55544 30.1797C3.23985 30.9489 2.21447 32.0421 1.60897 33.3212C1.00347 34.6003 0.84504 36.0078 1.15372 37.3656C1.4624 38.7235 2.22433 39.9708 3.34315 40.9497C4.46197 41.9287 5.88743 42.5954 7.43928 42.8655C8.99113 43.1356 10.5997 42.997 12.0615 42.4672C13.5233 41.9373 14.7727 41.0401 15.6518 39.889C16.5308 38.7378 17 37.3845 17 36V29M9 29H17M9 29C6.87827 29 4.84344 28.2625 3.34315 26.9497C1.84286 25.637 1 23.8565 1 22C1 20.1435 1.84286 18.363 3.34315 17.0503C4.84344 15.7375 6.87827 15 9 15M17 29V15M17 1H25C27.1217 1 29.1566 1.7375 30.6569 3.05025C32.1571 4.36301 33 6.14348 33 8C33 9.85651 32.1571 11.637 30.6569 12.9497C29.1566 14.2625 27.1217 15 25 15H17M17 1V15M17 1H9C6.87827 1 4.84344 1.7375 3.34315 3.05025C1.84286 4.36301 1 6.14348 1 8C1 9.85651 1.84286 11.637 3.34315 12.9497C4.84344 14.2625 6.87827 15 9 15M17 15H9" stroke="#A3B18A" stroke-linecap="round" stroke-linejoin="round"/>
                          </svg>
                          <svg xmlns="http://www.w3.org/2000/svg" width="28" height="38" viewBox="0 0 28 38" fill="none" v-if="theme==2 || theme==3">
                            <path d="M20.099 14.2199H12.8347V0H20.099C24.0194 0 27.2082 3.18883 27.2082 7.10917C27.2082 11.0295 24.0194 14.2199 20.099 14.2199ZM15.1638 11.8908H20.099C22.7353 11.8908 24.8791 9.74542 24.8791 7.11075C24.8791 4.47608 22.7337 2.33067 20.099 2.33067H15.1638V11.8908ZM15.1638 14.2199H7.90103C3.9807 14.2199 0.791866 11.0311 0.791866 7.11075C0.791866 3.19042 3.9807 0 7.90103 0H15.1654V14.2199H15.1638ZM7.90103 2.32908C5.26478 2.32908 3.12095 4.4745 3.12095 7.10917C3.12095 9.74383 5.26478 11.8908 7.90103 11.8908H12.8363V2.32908H7.90103ZM15.1638 26.1092H7.90103C3.9807 26.1092 0.791866 22.9203 0.791866 19C0.791866 15.0797 3.9807 11.8908 7.90103 11.8908H15.1654V26.1092H15.1638ZM7.90103 14.2199C5.26478 14.2199 3.12095 16.3653 3.12095 19C3.12095 21.6347 5.26637 23.7801 7.90103 23.7801H12.8363V14.2199H7.90103ZM7.93903 38C3.99812 38 0.790283 34.8112 0.790283 30.8908C0.790283 26.9705 3.97912 23.7817 7.89945 23.7817H15.1638V30.8133C15.1638 34.7763 11.9227 38 7.93903 38ZM7.90103 26.1092C6.63379 26.1108 5.41893 26.615 4.52285 27.5111C3.62678 28.4071 3.12263 29.622 3.12095 30.8893C3.12095 33.5255 5.2822 35.6693 7.94062 35.6693C10.6402 35.6693 12.8379 33.4907 12.8379 30.8117V26.1092H7.90103ZM20.099 26.1092H19.9439C16.0235 26.1092 12.8347 22.9203 12.8347 19C12.8347 15.0797 16.0235 11.8908 19.9439 11.8908H20.099C24.0194 11.8908 27.2082 15.0797 27.2082 19C27.2082 22.9203 24.0194 26.1092 20.099 26.1092ZM19.9454 14.2199C17.3092 14.2199 15.1654 16.3653 15.1654 19C15.1654 21.6347 17.3108 23.7801 19.9454 23.7801H20.1006C22.7369 23.7801 24.8807 21.6347 24.8807 19C24.8807 16.3653 22.7337 14.2199 20.099 14.2199H19.9454Z" fill="#1D267D"/>
                          </svg>
                    </div>
                    <h2 :class="theme==0?'service-h2-1':theme==1?'service-h2-2':'service-h2-3'">UI/UX</h2>
                    <ul :class="theme==0?'service-list-1':theme==1?'service-list-2':'service-list-3'">
                        <li :class="theme==0?'service-li-1':theme==1?'service-li-2':'service-li-3'">UI Design</li>
                        <li :class="theme==0?'service-li-1':theme==1?'service-li-2':'service-li-3'">WireFraming</li>
                        <li :class="theme==0?'service-li-1':theme==1?'service-li-2':'service-li-3'">Figma</li>
                    </ul>
                </div>
            </div>
         </div>
    </div>
</template>

<script>
export default {
    computed:{
      bg(){
        let theme = this.$store.getters['getTheme']
            if(theme==0){
                return `background:#344E41`
            }
            else if(theme==1){
                return `background:#A3B18A`
            }
            else if(theme==2){
              return 'background:#0C134F'
            }
            else if(theme==3){
              return 'background:#1D267D'
            }
            else{
              return `background:#344E41`
            }
        },
        text(){
            let theme = this.$store.getters['getTheme']
            if(theme==0){
                return `color:#344E41`
            }
            else{
                return `color:`
            }
        },
        theme(){
            return this.$store.getters['getTheme']
        },
    }
}
</script>

<style scoped>
@media only screen and (max-width:480px){
    #fourth-mobile{
        width:100vw;
        height: fit-content;
        overflow-x: hidden;
        overflow-y:auto;
        position: relative;
        margin-left:0 !important;
       z-index: 0;
       padding-top:3vh;
       margin-top:-0.3vh;
    }
    .text-theme-1{
        color: #A3B18A;
    font-family: Roboto;
    font-size: 28px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    text-align:center;
    margin-bottom:0px;
    }
    .text-theme-2{
        color: #344E41;
        font-family: Roboto;
        font-size: 28px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        text-align: center;
        margin-bottom:0px;
    }
    .text-theme-3{
        color: #5C469C;
        font-family: Roboto;
        font-size: 28px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        text-align: center;
        margin-bottom:0px;
    }
    .text-theme-4{
        color: #D4ADFC;
        font-family: Roboto;
        font-size: 28px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        text-align: center;
        margin-bottom:0px;
    }
    .h3-theme-1{
        color: #C8CFCC;
        font-family: Roboto;
        font-size: 17px;
        font-style: normal;
        font-weight: 350;
        line-height: normal;
        text-align: center;
        margin-top:2px;
    }
    .h3-theme-2{
        color: #588157;
    font-family: Roboto;
    font-size: 17px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
        margin-top:2px;
        text-align: center;
    }
    #qualificatons{
        width:100vw;
        margin-top:3vh;
        display: grid;
        grid-template-columns: 45vw 10vw 45vw;
    }
    .qualification{
        margin-left:4vw;
    }
    .degree-1{
        color: #C8CFCC;
font-family: Roboto;
font-size: 24px;
font-style: normal;
font-weight: 600;
line-height: normal;
    }
    .degree-2{
        color: #344E41;
        font-family: Roboto;
        font-size: 24px;
        font-style: normal;
        font-weight: 060;
        line-height: normal;
    }
    
    .place-1{
        color: rgba(255, 255, 255, 0.82);
        font-family: Roboto;
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        margin-bottom:0px;
        margin-top:3px;
    }
    
    .place-2{
        color: #3A5A40;
        font-family: Roboto;
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        margin-bottom:0px;
        margin-top:3px;
    }

    .duration-1{
        color: rgba(218, 215, 205, 0.70);
        font-family: Roboto;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        margin-top:3px;
        margin-bottom:0px;
    }

    .duration-2{
        color: #588157;
font-family: Roboto;
font-size: 16px;
font-style: normal;
font-weight: 400;
line-height: normal;
margin-top:3px;
        margin-bottom:0px;
    }
    #qual-divider{
        margin-top:3vh;
        padding-left:2vw;
    }
    .qual-circle{
       margin-left:2vw;
       margin-top:0px;
       margin-bottom:0px;
    }
    .qual-line-1{
        width: 1px;
        height: 140px;
        background: #DAD7CD;
        margin-left:3.8vw;
        margin-top:0px;
        margin-bottom:0px;
    }
    .qual-line-2{
        width: 1px;
        height: 140px;
        background: #588157;
        margin-left:3.8vw;
        margin-top:0px;
        margin-bottom:0px;
    }
    .qual-line-3{
        width: 1px;
        height: 140px;
        background: rgba(218, 215, 205, 0.70);
        margin-left:3.8vw;
        margin-top:0px;
        margin-bottom:0px;
    }
    .qual-line-4{
        width: 1px;
        height: 140px;
        background: rgba(218, 215, 205, 0.80);
        margin-left:3.8vw;
        margin-top:0px;
        margin-bottom:0px;
    }
    #services{
        width:100vw;
        margin-top:3vh;
    }
    .service-row{
        width:100vw;
        margin-top:1.6vh;
        margin-bottom:3.6vh;
        display: grid;
        grid-template-columns: repeat(2,50vw);
    }
    .service-card-1{
        border-radius: 5px;
        background: #A3B18A;
        box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
        width:45vw;
        height:30vh;
        margin-left:2.5vw;
    }
    .service-card-2{
        border-radius: 5px;
        background: #344E41;
        box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
        width:45vw;
        height:30vh;
        margin-left:2.5vw;
    }
    .service-card-3{
        border-radius: 5px;
        background: #D4ADFC;
        box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
        width:45vw;
        height:30vh;
        margin-left:2.5vw;
    }
    .service-card-4{
        border-radius: 5px;
        background: #D4ADFC;
        box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
        width:45vw;
        height:30vh;
        margin-left:2.5vw;
    }
    .service-icon{
        margin-top:2.8vh;
        margin-left:2.8vw;
        margin-bottom:0px;
    }
    .service-h2-1{
        color: #588157;
text-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
font-family: Roboto;
font-size: 22px;
font-style: normal;
font-weight: 500;
line-height: normal;
margin-top:2px;
margin-left:5vw;
margin-bottom:0px;
    }
    .service-h2-2{
        color: #A3B18A;
        text-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
        font-family: Roboto;
        font-size: 22px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        margin-top:2px;
margin-left:5vw;
margin-bottom:0px
    }
    .service-h2-3{
        color: #1D267D;
        text-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
        font-family: Roboto;
        font-size: 22px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        margin-top:2px;
margin-left:5vw;
margin-bottom:0px
    }
    ul{
        margin-top:5px;
    }
    service-li-1::before{
        color:#344E41
    }
    service-li-2::before{
         color: #A3B18A;
    }
    service-li-3::before{
        color: #5C469C;;
   }
    .service-li-1{
        color:#344E41;
        margin-top:2px;
        margin-bottom:2px;
    }
    .service-li-2{
        color:#A3B18A;
        margin-top:2px;
        margin-bottom:2px;
    }
    .service-li-3{
        color: #1D267D;
        margin-top:2px;
        margin-bottom:2px;
    }

    
}

@media only screen and (min-width:481px) and (max-width:960px){
    #fourth-mobile{
        display: none;
    }
}
@media only screen and (min-width:961px){
    #fourth-mobile{
        display: none;
    }
}
</style>