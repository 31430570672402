<template>
    <div id="second-desk" :style="bg">
        <h2 :class="theme==0?'text-theme-1':theme==1?'text-theme-2':theme==2?'text-theme-3':'text-theme-4'">About Me</h2>
        <h3 :class="theme==1?'h3-theme-2':'h3-theme-1'">My Introduction</h3>
        <h3 :class="theme==1?'theme-2':'theme-1'">Freelancing aficionado actively seeking avenues for skill elevation and the acquisition of new proficiencies. During my downtime, I transform the ordinary into the extraordinary, infusing creativity into every endeavor. Presently, I'm engaged in freelance endeavors with Knk Soft Infotech, infusing innovation and dedication into every project, shaping digital horizons with precision and flair.What do I do other than coding?</h3>
       <ul id="ul">
        <li :class="theme==1?'li-theme-2':'li-theme-1'">Embark on nature's canvas, I ascend peaks, each step a poetic journey.</li>
        <li :class="theme==1?'li-theme-2':'li-theme-1'">In the realm of culinary artistry, I orchestrate flavors, crafting culinary symphonies.</li>
        <li :class="theme==1?'li-theme-2':'li-theme-1'">With nimble hands, I breathe life into metallic marvels, forging pathways to innovation.</li>
        <li :class="theme==1?'li-theme-2':'li-theme-1'">Amidst melodies, I find solace, weaving tales of tranquility amidst the musical tapestry.</li>
        <li :class="theme==1?'li-theme-2':'li-theme-1'">Venturing into the labyrinth of the mind, I decipher the intricate dance of human psyche, unraveling mysteries with every revelation.</li>
       </ul>
       <div id="experience">
        <div id="exp-col-1" >
            <h3 :class="theme==1?'exp-h3-2':'exp-h3-1'">01+</h3>
            <h4 :class="theme==1?'exp-h4-2':'exp-h4-1'">Years experince</h4>
        </div>
        <div id="exp-col-2">
          <h3 :class="theme==1?'exp-h3-2':'exp-h3-1'">20+</h3>
          <h4 :class="theme==1?'exp-h4-2':'exp-h4-1'">Completed projects</h4>
        </div>
       </div>
       <div :class="theme==0?'pg-2-btn-1':theme==1?'pg-2-btn-2':theme==2?'pg-2-btn-3':'pg-2-btn-4'" @click="redirect">
        <h3>Download CV</h3>
        <svg xmlns="http://www.w3.org/2000/svg" width="44" height="46" viewBox="0 0 44 46" fill="none" v-if="theme==0">
            <g filter="url(#filter0_d_13_137)">
              <path d="M30.75 13.6038C34.4837 13.6254 36.506 13.8 37.8244 15.1824C39.3333 16.7646 39.3333 19.3098 39.3333 24.4002V26.2002C39.3333 31.2924 39.3333 33.8376 37.8244 35.4198C36.3171 37.0002 33.8881 37.0002 29.0333 37.0002H15.3C10.4453 37.0002 8.01618 37.0002 6.50895 35.4198C5 33.8358 5 31.2924 5 26.2002V24.4002C5 19.3098 5 16.7646 6.50895 15.1824C7.82735 13.8 9.84958 13.6254 13.5833 13.6038" stroke="#344E41" stroke-linecap="round"/>
              <path d="M22.1667 1V24.4M22.1667 24.4L17.0167 18.1M22.1667 24.4L27.3167 18.1" stroke="#344E41" stroke-linecap="round" stroke-linejoin="round"/>
            </g>
            <defs>
              <filter id="filter0_d_13_137" x="0.5" y="0.5" width="43.3334" height="45.0002" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                <feFlood flood-opacity="0" result="BackgroundImageFix"/>
                <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                <feOffset dy="4"/>
                <feGaussianBlur stdDeviation="2"/>
                <feComposite in2="hardAlpha" operator="out"/>
                <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"/>
                <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_13_137"/>
                <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_13_137" result="shape"/>
              </filter>
            </defs>
          </svg>
          <svg xmlns="http://www.w3.org/2000/svg" width="44" height="46" viewBox="0 0 44 46" fill="none" v-if="theme==1">
            <g filter="url(#filter0_d_17_182)">
              <path d="M30.75 13.6038C34.4837 13.6254 36.506 13.8 37.8244 15.1824C39.3333 16.7646 39.3333 19.3098 39.3333 24.4002V26.2002C39.3333 31.2924 39.3333 33.8376 37.8244 35.4198C36.3171 37.0002 33.8881 37.0002 29.0333 37.0002H15.3C10.4453 37.0002 8.01618 37.0002 6.50895 35.4198C5 33.8358 5 31.2924 5 26.2002V24.4002C5 19.3098 5 16.7646 6.50895 15.1824C7.82735 13.8 9.84958 13.6254 13.5833 13.6038" stroke="#A3B18A" stroke-linecap="round"/>
              <path d="M22.1667 1V24.4M22.1667 24.4L17.0167 18.1M22.1667 24.4L27.3167 18.1" stroke="#A3B18A" stroke-linecap="round" stroke-linejoin="round"/>
            </g>
            <defs>
              <filter id="filter0_d_17_182" x="0.5" y="0.5" width="43.3334" height="45.0002" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                <feFlood flood-opacity="0" result="BackgroundImageFix"/>
                <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                <feOffset dy="4"/>
                <feGaussianBlur stdDeviation="2"/>
                <feComposite in2="hardAlpha" operator="out"/>
                <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"/>
                <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_17_182"/>
                <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_17_182" result="shape"/>
              </filter>
            </defs>
          </svg>
          <svg xmlns="http://www.w3.org/2000/svg" width="44" height="46" viewBox="0 0 44 46" fill="none" v-if="theme==2">
            <g filter="url(#filter0_d_229_266)">
              <path d="M30.75 13.6038C34.4837 13.6254 36.506 13.8 37.8244 15.1824C39.3333 16.7646 39.3333 19.3098 39.3333 24.4002V26.2002C39.3333 31.2924 39.3333 33.8376 37.8244 35.4198C36.3171 37.0002 33.8881 37.0002 29.0333 37.0002H15.3C10.4453 37.0002 8.01618 37.0002 6.50895 35.4198C5 33.8358 5 31.2924 5 26.2002V24.4002C5 19.3098 5 16.7646 6.50895 15.1824C7.82735 13.8 9.84958 13.6254 13.5833 13.6038" stroke="#212B8B" stroke-linecap="round"/>
              <path d="M22.1667 1V24.4M22.1667 24.4L17.0167 18.1M22.1667 24.4L27.3167 18.1" stroke="#212B8B" stroke-linecap="round" stroke-linejoin="round"/>
            </g>
            <defs>
              <filter id="filter0_d_229_266" x="0.5" y="0.5" width="43.3334" height="45.0002" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                <feFlood flood-opacity="0" result="BackgroundImageFix"/>
                <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                <feOffset dy="4"/>
                <feGaussianBlur stdDeviation="2"/>
                <feComposite in2="hardAlpha" operator="out"/>
                <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"/>
                <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_229_266"/>
                <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_229_266" result="shape"/>
              </filter>
            </defs>
          </svg>
          <svg xmlns="http://www.w3.org/2000/svg" width="44" height="46" viewBox="0 0 44 46" fill="none" v-if="theme==3">
            <g filter="url(#filter0_d_229_269)">
              <path d="M30.75 13.6038C34.4837 13.6254 36.506 13.8 37.8244 15.1824C39.3333 16.7646 39.3333 19.3098 39.3333 24.4002V26.2002C39.3333 31.2924 39.3333 33.8376 37.8244 35.4198C36.3171 37.0002 33.8881 37.0002 29.0333 37.0002H15.3C10.4453 37.0002 8.01618 37.0002 6.50895 35.4198C5 33.8358 5 31.2924 5 26.2002V24.4002C5 19.3098 5 16.7646 6.50895 15.1824C7.82735 13.8 9.84958 13.6254 13.5833 13.6038" stroke="#0C134F" stroke-linecap="round"/>
              <path d="M22.1667 1V24.4M22.1667 24.4L17.0167 18.1M22.1667 24.4L27.3167 18.1" stroke="#0C134F" stroke-linecap="round" stroke-linejoin="round"/>
            </g>
            <defs>
              <filter id="filter0_d_229_269" x="0.5" y="0.5" width="43.3334" height="45.0002" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                <feFlood flood-opacity="0" result="BackgroundImageFix"/>
                <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                <feOffset dy="4"/>
                <feGaussianBlur stdDeviation="2"/>
                <feComposite in2="hardAlpha" operator="out"/>
                <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"/>
                <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_229_269"/>
                <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_229_269" result="shape"/>
              </filter>
            </defs>
          </svg>
       </div>
    </div>
</template>

<script>
export default {
    methods:{

        redirect(){
            window.open('https://firebasestorage.googleapis.com/v0/b/portfolio-b3f3a.appspot.com/o/Abhipray-Dumka.pdf?alt=media&token=488de2ec-6491-4beb-9333-681ea386cb86','_blank')
        }
    },
    computed:{
        bg(){
            let theme = this.$store.getters['getTheme']
            if(theme==0){
                return `background:#344E41`
            }
            else if(theme==1){
                return `background:#A3B18A`
            }
            else if(theme==2){
              return 'background:#0C134F'
            }
            else if(theme==3){
              return 'background:#1D267D'
            }
            else{
              return `background:#344E41`
            }
        },
        text(){
            let theme = this.$store.getters['getTheme']
            if(theme==0){
                return `color:#344E41`
            }
            else{
                return `color:`
            }
        },
        theme(){
            return this.$store.getters['getTheme']
        },
    }, 
}
</script>

<style scoped>
@media only screen and (max-width:480px){
    #second-desk{
        display: none;
    }
}
@media only screen and (min-width:481px) and (max-width:960px){
    #second-desk{
        width:100vw;
        position: relative;
        height:auto;
        overflow-x:hidden;
        overflow-y:auto;
        padding-top:1vh;
        }
        #second-desk::-webkit-scrollbar{
            display: none;
            }
            .text-theme-1{
                color: #A3B18A;
            font-family: Roboto;
            font-size: 48px;
            font-style: normal;
            font-weight: 700;
            line-height: normal;
            text-align:center;
            margin-bottom:0px;
            }
            .text-theme-2{
                color: #344E41;
                font-family: Roboto;
                font-size: 48px;
                font-style: normal;
                font-weight: 700;
                line-height: normal;
                text-align: center;
                margin-bottom:0px;
            }
            .text-theme-3{
                color: #5C469C;
                font-family: Roboto;
                font-size: 48px;
                font-style: normal;
                font-weight: 700;
                line-height: normal;
                text-align: center;
                margin-bottom:0px;
            }
            .text-theme-4{
                color: #D4ADFC;
                font-family: Roboto;
                font-size: 48px;
                font-style: normal;
                font-weight: 700;
                line-height: normal;
                text-align: center;
                margin-bottom:0px;
            }
            .h3-theme-1{
                color: #C8CFCC;
                font-family: Roboto;
                font-size: 24px;
                font-style: normal;
                font-weight: 350;
                line-height: normal;
                text-align: center;
                margin-top:2px;
            }
            .h3-theme-2{
                color: #588157;
            font-family: Roboto;
            font-size: 24px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
                margin-top:2px;
                text-align: center;
            }
            .theme-1{
                color: rgba(255, 255, 255, 0.73);;
            text-align: center;
            font-family: Roboto;
            font-size: 32px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            text-align: center;
            width:80vw;
            margin-left:10vw;
            }
            .theme-2{
                color: #588157;
                text-align: center;
                font-family: Roboto;
                font-size: 32px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
                text-align: center;
            width:80vw;
            margin-left:10vw;
            }
            #ul{
                list-style-type:none;
                
            }
            .li-theme-1{
            color: rgba(255, 255, 255, 0.73);
            font-family: Roboto;
            font-size: 29px;
            font-style: normal;
            font-weight: 300;
            line-height: normal;
            margin-top:1.5vh;
            margin-bottom:1.5vh;
            text-align: center;
            }
            .li-theme-2{
                color: #588157;
                font-family: Roboto;
                font-size: 29px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
                margin-top:1.5vh;
            margin-bottom:1.5vh;
            text-align: center;
            }
            #experience{
                margin-top:1.9vh;
                display: grid;
                width: 100vw;
                display:flex;
                justify-content: center;
                align-items: center;
                gap:5vw;
            }
            #exp-col-1{
                width:30vw;
            }
            #exp-col-2{
                width:30vw;
            }
            .exp-h3-1{color: rgba(255, 255, 255, 0.83);
                font-family: Inter;
                font-size: 38px;
                font-style: normal;
                font-weight: 500;
                line-height: normal;
                margin-bottom:0px;
                text-align:center;
            }
            .exp-h3-2{
                color: #3A5A40;
            font-family: Inter;
            font-size: 38px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
            margin-bottom:0px;
            text-align: center;
            }
            .exp-h4-1{
                color: rgba(255, 255, 255, 0.53);
            font-family: Inter;
            font-size: 28px;
            font-style: normal;
            font-weight: 100;
            margin-top:2px;
            text-align: center;
            }
            .exp-h4-2{
                color: #588157;
            font-family: Inter;
            font-size: 28px;
            font-style: normal;
            font-weight: 200;
            line-height: normal;
            margin-top:2px;
            text-align: center;
            }
            .pg-2-btn-1{
                width:40vw;
                margin-left:30vw;
                height:8vh;
                border-radius: 10px;
                box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
                background: #A3B18A;
                cursor: pointer;
                display:flex;
                justify-content: center;
                align-items: center;
            }
            .pg-2-btn-1 h3{
                color: #344E41;
            font-family: Inter;
            font-size: 34px;
            font-style: normal;
            font-weight: 500;
            line-height: 8vh;
            }
            .pg-2-btn-1 svg,
            .pg-2-btn-2 svg,
            .pg-2-btn-3 svg,
            .pg-2-btn-4 svg{
                margin-left:1.5vw;
        margin-top:0.5vh;
            }
            .pg-2-btn-2{
                width:40vw;
                margin-left:30vw;
                height:8vh;
                border-radius: 10px;
            background: #344E41;
            box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
        cursor: pointer;    
        display:flex;
        justify-content: center;
        align-items: center;
        }
            .pg-2-btn-2 h3{
                color: #A3B18A;
            font-family: Inter;
            font-size: 34px;
            font-style: normal;
                font-weight: 500;
                line-height: 8vh;
            }
            .pg-2-btn-3{
                width:40vw;
                margin-left:30vw;
                height:8vh;
                border-radius: 10px;
            background: #344E41;
            box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
        cursor: pointer;    
        display:flex;
        justify-content: center;
        align-items: center;
                background: #D4ADFC;
            }
            .pg-2-btn-3 h3{
                color: #1D267D;
                font-family: Inter;
                font-size: 34px;
                font-style: normal;
                    font-weight: 500;
                    line-height: 8vh;
            }
            .pg-2-btn-4{
                width:40vw;
                margin-left:30vw;
                height:8vh;
                border-radius: 10px;
            background: #344E41;
            box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
        cursor: pointer;    
        display:flex;
        justify-content: center;
        align-items: center;
                background: #A2A1B5;
            }
            .pg-2-btn-4 h3{
                color: #1D267D;
                font-family: Inter;
                font-size: 34px;
                font-style: normal;
                    font-weight: 500;
                    line-height: 8vh;
            }
}
@media only screen and (min-width:961px){
#second-desk{
width:100vw;
position: relative;
height:fit-content;
overflow-x:hidden;
overflow-y:auto;
padding-top:4vh;
}
#second-desk::-webkit-scrollbar{
    display: none;
    }
    .text-theme-1{
        color: #A3B18A;
    font-family: Roboto;
    font-size: 48px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    text-align:center;
    margin-bottom:0px;
    }
    .text-theme-2{
        color: #344E41;
        font-family: Roboto;
        font-size: 48px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        text-align: center;
        margin-bottom:0px;
    }
    .text-theme-3{
        color: #5C469C;
        font-family: Roboto;
        font-size: 48px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        text-align: center;
        margin-bottom:0px;
    }
    .text-theme-4{
        color: #D4ADFC;
        font-family: Roboto;
        font-size: 48px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        text-align: center;
        margin-bottom:0px;
    }
    .h3-theme-1{
        color: #C8CFCC;
        font-family: Roboto;
        font-size: 24px;
        font-style: normal;
        font-weight: 350;
        line-height: normal;
        text-align: center;
        margin-top:2px;
    }
    .h3-theme-2{
        color: #588157;
    font-family: Roboto;
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
        margin-top:2px;
        text-align: center;
    }
    .theme-1{
        color: rgba(255, 255, 255, 0.73);;
    text-align: center;
    font-family: Roboto;
    font-size: 28px;
    font-style: normal;
    font-weight: 100;
    line-height: normal;
    text-align: center;
    width:70vw;
    margin-left:15vw;
    }
    .theme-2{
        color: #588157;
        text-align: center;
        font-family: Roboto;
        font-size: 28px;
        font-style: normal;
        font-weight: 100;
        line-height: normal;
        text-align: center;
    width:70vw;
    margin-left:15vw;
    }
    #ul{
        list-style-type:none;
        
    }
    .li-theme-1{
        color: rgba(255, 255, 255, 0.63);
    font-family: Roboto;
    font-size: 22px;
    font-style: normal;
    font-weight: 100;
    line-height: normal;
    margin-top:1.5vh;
    margin-bottom:1.5vh;
    text-align: center;
    }
    .li-theme-2{
        color: #588157;
        font-family: Roboto;
        font-size: 22px;
        font-style: normal;
        font-weight: 100;
        line-height: normal;
        margin-top:1.5vh;
    margin-bottom:1.5vh;
    text-align: center;
    }
    #experience{
        margin-top:2.9vh;
        display: grid;
        width: 60vw;
        margin-left:20vw;
        grid-template-columns: repeat(2,30vw);
    }
    #exp-col-1{
        width:30vw;
        margin-left:8vw;
    }
    #exp-col-2{
        width:30vw;
        margin-left:4vw;
    }
    .exp-h3-1{color: rgba(255, 255, 255, 0.83);
        font-family: Inter;
    font-size: 48px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    margin-left:4.6vw;
    margin-bottom:0px;
    }
    .exp-h3-2{
        color: #3A5A40;
    font-family: Inter;
    font-size: 48px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    margin-left:4.6vw;
    margin-bottom:0px;
    }
    .exp-h4-1{
        color: rgba(255, 255, 255, 0.53);
    font-family: Inter;
    font-size: 28px;
    font-style: normal;
    font-weight: 100;
    margin-top:2px;
    }
    .exp-h4-2{
        color: #588157;
    font-family: Inter;
    font-size: 28px;
    font-style: normal;
    font-weight: 200;
    line-height: normal;
    margin-top:2px;
    }
    .pg-2-btn-1{
        width:25vw;
        margin-left:37.5vw;
        height:8vh;
        border-radius: 10px;
        box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
        background: #A3B18A;
        cursor: pointer;
        display:flex;
        justify-content: center;
        align-items: center;
    }
    .pg-2-btn-1 h3{
        color: #344E41;
    font-family: Inter;
    font-size: 34px;
    font-style: normal;
    font-weight: 500;
    line-height: 8vh;
    }
    .pg-2-btn-1 svg,
    .pg-2-btn-2 svg,
    .pg-2-btn-3 svg,
    .pg-2-btn-4 svg{
        margin-left:1.5vw;
margin-top:0.5vh;
    }
    .pg-2-btn-2{
        width:25vw;
        margin-left:37.5vw;
        height:8vh;
        border-radius: 10px;
    background: #344E41;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
cursor: pointer;    
display:flex;
justify-content: center;
align-items: center;
}
    .pg-2-btn-2 h3{
        color: #A3B18A;
    font-family: Inter;
    font-size: 34px;
    font-style: normal;
        font-weight: 500;
        line-height: 8vh;
    }
    .pg-2-btn-3{
        width:25vw;
        margin-left:37.5vw;
        height:8vh;
        border-radius: 10px;
        box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
        background: #D4ADFC;
        cursor: pointer;
        display:flex;
        justify-content: center;
        align-items: center;
    }
    .pg-2-btn-3 h3{
        color: #1D267D;
    font-family: Inter;
    font-size: 34px;
    font-style: normal;
    font-weight: 500;
    line-height: 8vh;
    }
    .pg-2-btn-4{
        width:25vw;
        margin-left:37.5vw;
        height:8vh;
        border-radius: 10px;
        box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
        background: #A2A1B5;
        cursor: pointer;
        display:flex;
        justify-content: center;
        align-items: center;
    }
    .pg-2-btn-4 h3{
        color: #1D267D;
    font-family: Inter;
    font-size: 34px;
    font-style: normal;
    font-weight: 500;
    line-height: 8vh;
    }
}


</style>