import { createStore } from 'vuex'

interface state{
  theme:number,
  page:number
}
export default createStore({
  state: {
    theme:0,
    page:0
  },
  getters: {
    getPage(state){
      return state.page;
    },
    getTheme(state){
      return state.theme;
    }
  },
  mutations: {
    setTheme(state){
      if(state.theme == 3){
        state.theme=0;
      }
      else
      state.theme=state.theme + 1;
    },
    setPage(state,payload){
      state.page=payload.value
    }
  },
  actions: {
    async emailList(state,payload){
       return ''
    }
  },
  modules: {
  }
})
