<template>
    <div id="btn-cmp">
        <div id="btn-large" @click="this.$emit('top-scroll')">
            <svg xmlns="http://www.w3.org/2000/svg" width="58" height="58" viewBox="0 0 58 58" fill="none" v-if="theme==0">
                <g filter="url(#filter0_d_125_6)">
                  <rect x="4" width="50" height="50" rx="5" fill="#A3B18A"/>
                  <path d="M44.6913 23.6629C44.474 23.8627 44.2158 24.0211 43.9315 24.1293C43.6471 24.2374 43.3423 24.2931 43.0344 24.2931C42.7265 24.2931 42.4217 24.2374 42.1374 24.1293C41.853 24.0211 41.5948 23.8627 41.3775 23.6629L31.3403 14.4701V40.7149C31.3403 41.2831 31.0939 41.8281 30.6552 42.2298C30.2165 42.6316 29.6215 42.8574 29.0011 42.8574C28.3807 42.8574 27.7858 42.6316 27.3471 42.2298C26.9084 41.8281 26.6619 41.2831 26.6619 40.7149V14.4701L16.6209 23.6629C16.1815 24.0654 15.5855 24.2915 14.964 24.2915C14.3425 24.2915 13.7465 24.0654 13.3071 23.6629C12.8676 23.2604 12.6207 22.7146 12.6207 22.1454C12.6207 21.5762 12.8676 21.0303 13.3071 20.6278L27.3423 7.7732C27.5596 7.57347 27.8178 7.415 28.1021 7.30686C28.3865 7.19873 28.6913 7.14307 28.9992 7.14307C29.3071 7.14307 29.6119 7.19873 29.8962 7.30686C30.1806 7.415 30.4388 7.57347 30.6561 7.7732L44.6913 20.6278C44.9094 20.8269 45.0824 21.0634 45.2005 21.3238C45.3186 21.5842 45.3793 21.8634 45.3793 22.1454C45.3793 22.4273 45.3186 22.7065 45.2005 22.967C45.0824 23.2274 44.9094 23.4639 44.6913 23.6629Z" fill="#344E41"/>
                </g>
                <defs>
                  <filter id="filter0_d_125_6" x="0" y="0" width="58" height="58" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                    <feFlood flood-opacity="0" result="BackgroundImageFix"/>
                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                    <feOffset dy="4"/>
                    <feGaussianBlur stdDeviation="2"/>
                    <feComposite in2="hardAlpha" operator="out"/>
                    <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"/>
                    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_125_6"/>
                    <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_125_6" result="shape"/>
                  </filter>
                </defs>
              </svg>
            <svg xmlns="http://www.w3.org/2000/svg" width="58" height="58" viewBox="0 0 58 58" fill="none" v-if="theme==1">
                <g filter="url(#filter0_d_125_4)">
                  <rect x="4" width="50" height="50" rx="5" fill="#344E41"/>
                  <path d="M44.6913 23.6629C44.474 23.8627 44.2157 24.0211 43.9314 24.1293C43.6471 24.2374 43.3422 24.2931 43.0343 24.2931C42.7265 24.2931 42.4216 24.2374 42.1373 24.1293C41.853 24.0211 41.5947 23.8627 41.3774 23.6629L31.3403 14.4701V40.7149C31.3403 41.2831 31.0938 41.8281 30.6551 42.2298C30.2165 42.6316 29.6215 42.8574 29.0011 42.8574C28.3807 42.8574 27.7857 42.6316 27.347 42.2298C26.9083 41.8281 26.6619 41.2831 26.6619 40.7149V14.4701L16.6209 23.6629C16.1814 24.0654 15.5854 24.2915 14.9639 24.2915C14.3425 24.2915 13.7464 24.0654 13.307 23.6629C12.8675 23.2604 12.6207 22.7146 12.6207 22.1454C12.6207 21.5762 12.8675 21.0303 13.307 20.6278L27.3422 7.7732C27.5595 7.57347 27.8177 7.415 28.1021 7.30686C28.3864 7.19873 28.6913 7.14307 28.9991 7.14307C29.307 7.14307 29.6118 7.19873 29.8962 7.30686C30.1805 7.415 30.4387 7.57347 30.6561 7.7732L44.6913 20.6278C44.9094 20.8269 45.0824 21.0634 45.2004 21.3238C45.3185 21.5842 45.3793 21.8634 45.3793 22.1454C45.3793 22.4273 45.3185 22.7065 45.2004 22.967C45.0824 23.2274 44.9094 23.4639 44.6913 23.6629Z" fill="#A3B18A"/>
                </g>
                <defs>
                  <filter id="filter0_d_125_4" x="0" y="0" width="58" height="58" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                    <feFlood flood-opacity="0" result="BackgroundImageFix"/>
                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                    <feOffset dy="4"/>
                    <feGaussianBlur stdDeviation="2"/>
                    <feComposite in2="hardAlpha" operator="out"/>
                    <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"/>
                    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_125_4"/>
                    <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_125_4" result="shape"/>
                  </filter>
                </defs>
              </svg>
              <svg xmlns="http://www.w3.org/2000/svg" width="58" height="58" viewBox="0 0 58 58" fill="none" v-if="theme==2 || theme==3">
                <g filter="url(#filter0_d_125_4)">
                  <rect x="4" width="50" height="50" rx="5" fill="#D4ADFC"/>
                  <path d="M44.6913 23.6629C44.474 23.8627 44.2157 24.0211 43.9314 24.1293C43.6471 24.2374 43.3422 24.2931 43.0343 24.2931C42.7265 24.2931 42.4216 24.2374 42.1373 24.1293C41.853 24.0211 41.5947 23.8627 41.3774 23.6629L31.3403 14.4701V40.7149C31.3403 41.2831 31.0938 41.8281 30.6551 42.2298C30.2165 42.6316 29.6215 42.8574 29.0011 42.8574C28.3807 42.8574 27.7857 42.6316 27.347 42.2298C26.9083 41.8281 26.6619 41.2831 26.6619 40.7149V14.4701L16.6209 23.6629C16.1814 24.0654 15.5854 24.2915 14.9639 24.2915C14.3425 24.2915 13.7464 24.0654 13.307 23.6629C12.8675 23.2604 12.6207 22.7146 12.6207 22.1454C12.6207 21.5762 12.8675 21.0303 13.307 20.6278L27.3422 7.7732C27.5595 7.57347 27.8177 7.415 28.1021 7.30686C28.3864 7.19873 28.6913 7.14307 28.9991 7.14307C29.307 7.14307 29.6118 7.19873 29.8962 7.30686C30.1805 7.415 30.4387 7.57347 30.6561 7.7732L44.6913 20.6278C44.9094 20.8269 45.0824 21.0634 45.2004 21.3238C45.3185 21.5842 45.3793 21.8634 45.3793 22.1454C45.3793 22.4273 45.3185 22.7065 45.2004 22.967C45.0824 23.2274 44.9094 23.4639 44.6913 23.6629Z" fill="#1D267D"/>
                </g>
                <defs>
                  <filter id="filter0_d_125_4" x="0" y="0" width="58" height="58" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                    <feFlood flood-opacity="0" result="BackgroundImageFix"/>
                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                    <feOffset dy="4"/>
                    <feGaussianBlur stdDeviation="2"/>
                    <feComposite in2="hardAlpha" operator="out"/>
                    <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"/>
                    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_125_4"/>
                    <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_125_4" result="shape"/>
                  </filter>
                </defs>
              </svg>
        </div>
        <div id="btn-small" @click="this.$emit('top-scroll')">
            <svg xmlns="http://www.w3.org/2000/svg" width="43" height="43" viewBox="0 0 43 43" fill="none" v-if="theme==0">
                <g filter="url(#filter0_d_2_559)">
                  <rect x="4" width="35" height="35" rx="5" fill="#A3B18A"/>
                  <path d="M32.4839 16.5639C32.3318 16.7037 32.151 16.8146 31.952 16.8903C31.753 16.966 31.5396 17.005 31.3241 17.005C31.1085 17.005 30.8952 16.966 30.6961 16.8903C30.4971 16.8146 30.3163 16.7037 30.1642 16.5639L23.1382 10.1289V28.5003C23.1382 28.898 22.9657 29.2795 22.6586 29.5607C22.3515 29.842 21.9351 30 21.5008 30C21.0665 30 20.65 29.842 20.3429 29.5607C20.0359 29.2795 19.8633 28.898 19.8633 28.5003V10.1289L12.8346 16.5639C12.527 16.8456 12.1098 17.0039 11.6748 17.0039C11.2397 17.0039 10.8225 16.8456 10.5149 16.5639C10.2073 16.2822 10.0345 15.9 10.0345 15.5016C10.0345 15.1032 10.2073 14.7211 10.5149 14.4393L20.3396 5.4411C20.4917 5.30128 20.6724 5.19035 20.8715 5.11466C21.0705 5.03896 21.2839 5 21.4994 5C21.7149 5 21.9283 5.03896 22.1273 5.11466C22.3264 5.19035 22.5071 5.30128 22.6593 5.4411L32.4839 14.4393C32.6366 14.5786 32.7577 14.7442 32.8403 14.9265C32.923 15.1088 32.9655 15.3042 32.9655 15.5016C32.9655 15.699 32.923 15.8944 32.8403 16.0767C32.7577 16.259 32.6366 16.4246 32.4839 16.5639Z" fill="#344E41"/>
                </g>
                <defs>
                  <filter id="filter0_d_2_559" x="0" y="0" width="43" height="43" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                    <feFlood flood-opacity="0" result="BackgroundImageFix"/>
                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                    <feOffset dy="4"/>
                    <feGaussianBlur stdDeviation="2"/>
                    <feComposite in2="hardAlpha" operator="out"/>
                    <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"/>
                    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_2_559"/>
                    <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_2_559" result="shape"/>
                  </filter>
                </defs>
              </svg>
              <svg xmlns="http://www.w3.org/2000/svg" width="43" height="43" viewBox="0 0 43 43" fill="none" v-if="theme==1">
                <g filter="url(#filter0_d_2_1481)">
                  <rect x="4" width="35" height="35" rx="5" fill="#344E41"/>
                  <path d="M32.4839 16.5639C32.3318 16.7037 32.151 16.8146 31.952 16.8903C31.753 16.966 31.5396 17.005 31.3241 17.005C31.1085 17.005 30.8952 16.966 30.6961 16.8903C30.4971 16.8146 30.3163 16.7037 30.1642 16.5639L23.1382 10.1289V28.5003C23.1382 28.898 22.9657 29.2795 22.6586 29.5607C22.3515 29.842 21.9351 30 21.5008 30C21.0665 30 20.65 29.842 20.3429 29.5607C20.0359 29.2795 19.8633 28.898 19.8633 28.5003V10.1289L12.8346 16.5639C12.527 16.8456 12.1098 17.0039 11.6748 17.0039C11.2397 17.0039 10.8225 16.8456 10.5149 16.5639C10.2073 16.2822 10.0345 15.9 10.0345 15.5016C10.0345 15.1032 10.2073 14.7211 10.5149 14.4393L20.3396 5.4411C20.4917 5.30128 20.6724 5.19035 20.8715 5.11466C21.0705 5.03896 21.2839 5 21.4994 5C21.7149 5 21.9283 5.03896 22.1273 5.11466C22.3264 5.19035 22.5071 5.30128 22.6593 5.4411L32.4839 14.4393C32.6366 14.5786 32.7577 14.7442 32.8403 14.9265C32.923 15.1088 32.9655 15.3042 32.9655 15.5016C32.9655 15.699 32.923 15.8944 32.8403 16.0767C32.7577 16.259 32.6366 16.4246 32.4839 16.5639Z" fill="#A3B18A"/>
                </g>
                <defs>
                  <filter id="filter0_d_2_1481" x="0" y="0" width="43" height="43" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                    <feFlood flood-opacity="0" result="BackgroundImageFix"/>
                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                    <feOffset dy="4"/>
                    <feGaussianBlur stdDeviation="2"/>
                    <feComposite in2="hardAlpha" operator="out"/>
                    <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"/>
                    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_2_1481"/>
                    <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_2_1481" result="shape"/>
                  </filter>
                </defs>
              </svg>
              <svg xmlns="http://www.w3.org/2000/svg" width="43" height="43" viewBox="0 0 43 43" fill="none" v-if="theme==2 || theme==3">
                <g filter="url(#filter0_d_207_129)">
                  <rect x="4" width="35" height="35" rx="5" fill="#D4ADFC"/>
                  <path d="M32.4838 16.5639C32.3317 16.7037 32.151 16.8146 31.9519 16.8903C31.7529 16.966 31.5395 17.005 31.324 17.005C31.1085 17.005 30.8951 16.966 30.6961 16.8903C30.497 16.8146 30.3163 16.7037 30.1641 16.5639L23.1382 10.1289V28.5003C23.1382 28.898 22.9656 29.2795 22.6586 29.5607C22.3515 29.842 21.935 30 21.5007 30C21.0664 30 20.6499 29.842 20.3429 29.5607C20.0358 29.2795 19.8633 28.898 19.8633 28.5003V10.1289L12.8346 16.5639C12.5269 16.8456 12.1097 17.0039 11.6747 17.0039C11.2397 17.0039 10.8225 16.8456 10.5149 16.5639C10.2072 16.2822 10.0344 15.9 10.0344 15.5016C10.0344 15.1032 10.2072 14.7211 10.5149 14.4393L20.3395 5.4411C20.4916 5.30128 20.6724 5.19035 20.8714 5.11466C21.0704 5.03896 21.2838 5 21.4993 5C21.7149 5 21.9283 5.03896 22.1273 5.11466C22.3263 5.19035 22.5071 5.30128 22.6592 5.4411L32.4838 14.4393C32.6365 14.5786 32.7576 14.7442 32.8403 14.9265C32.9229 15.1088 32.9655 15.3042 32.9655 15.5016C32.9655 15.699 32.9229 15.8944 32.8403 16.0767C32.7576 16.259 32.6365 16.4246 32.4838 16.5639Z" fill="#1D267D"/>
                </g>
                <defs>
                  <filter id="filter0_d_207_129" x="0" y="0" width="43" height="43" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                    <feFlood flood-opacity="0" result="BackgroundImageFix"/>
                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                    <feOffset dy="4"/>
                    <feGaussianBlur stdDeviation="2"/>
                    <feComposite in2="hardAlpha" operator="out"/>
                    <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"/>
                    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_207_129"/>
                    <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_207_129" result="shape"/>
                  </filter>
                </defs>
              </svg>
        </div>
    </div>
</template>

<script>
export default {
    computed:{
        theme(){
            return this.$store.getters['getTheme']
        },
    },
}
</script>

<style scoped>
@media only screen and (max-width:480px){
    #btn-large{
        display: none;
    }
    #btn-small{
      position:fixed;
      top:80vh;
      left:40vh;
    }
}
@media only screen and (min-width:481px) and (max-width:960px){
  #btn-small{
    display: none;
}
#btn-large{
    cursor: pointer;
    position:fixed;
  top:85vh;
  right:5vw;
}
}
@media only screen and (min-width:961px){
    #btn-small{
        display: none;
    }
    #btn-large{
        cursor: pointer;
        position:fixed;
      top:85vh;
      right:5vw;
    }

}
</style>